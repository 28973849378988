import {
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikTouched,
} from 'formik';
import { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import { IngredientTypes } from '../models/enums/IngredientTypes';
import { Units } from '../models/enums/Units';
import AuthenticatedUserProps from '../models/props/AuthenticatedUserProps';
import { Ingredient } from '../models/recipes/Ingredient';
import { BadRequestResponse } from '../models/responses/BadRequest';
import { HttpResponse } from '../services/HttpService';
import { successToast } from '../services/ToastService';
import ValidatedEnumDropdownWithLabel from './FormFields/ValidatedEnumDropdownWithLabel';
import ValidatedFieldWithLabel from './FormFields/ValidatedFieldWithLabel';
import SectionSubHeader from './Typography/SectionSubHeader';

import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';
import Button from './Button';

type IngredientFormProps = RouteComponentProps &
  AuthenticatedUserProps & {
    ingredient?: Ingredient;
    isEdit?: boolean;
    onSubmit: (
      ingredient: Ingredient,
      token: string
    ) => Promise<HttpResponse<{}>>;
  };

interface IngredientFormValues {
  name: string;
  type: IngredientTypes;
  cost: Cost[];
}

interface Cost {
  unit: Units;
  cost: number;
}

class IngredientForm extends Component<IngredientFormProps> {
  initialValues: IngredientFormValues;

  constructor(props: IngredientFormProps) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    const initialType = this.props.ingredient
      ? this.props.ingredient.type
      : IngredientTypes.Other;

    const initialCost: Cost[] = [];
    if (props.ingredient) {
      for (const key of Object.keys(props.ingredient.cost)) {
        const typedKey: keyof typeof Units = key as keyof typeof Units;
        const unit = Units[typedKey];
        initialCost.push({
          unit,
          cost: props.ingredient.cost[typedKey]!,
        });
      }
    } else {
      initialCost.push({ unit: Units.Unit, cost: 0 });
    }

    this.initialValues = {
      name: this.props.ingredient?.name ?? '',
      type: initialType,
      cost: initialCost,
    };
  }

  async handleFormSubmit(
    value: IngredientFormValues,
    helpers: FormikHelpers<IngredientFormValues>
  ) {
    const costMap: any = {};
    for (const entry of value.cost) {
      costMap[entry.unit] = entry.cost;
    }

    const result = await this.props.onSubmit(
      {
        id: this.props.ingredient?.id,
        name: value.name,
        type: value.type,
        cost: costMap,
      },
      this.props.userInfo.accessToken
    );

    if (result.ok) {
      if (this.props.isEdit) {
        successToast(`${value.name} was successfully updated!`);
      } else {
        successToast(`${value.name} was successfully added to the database!`);
      }

      this.props.history.push('..');
      return;
    }

    const response = result.body as BadRequestResponse;
    const nameError = response.errors.Name;
    if (nameError && nameError.length > 0) {
      helpers.setFieldError('name', nameError[0]);
    }

    helpers.setSubmitting(false);
  }

  render() {
    const schema = Yup.object().shape({
      name: Yup.string().required('This field is required'),
      cost: Yup.array()
        .of(
          Yup.object().shape({
            unit: Yup.string()
              .oneOf(Object.keys(Units))
              .required('This field is required'),
            cost: Yup.number().min(0).required('This field is required'),
          })
        )
        .min(1),
      type: Yup.number(),
    });

    return (
      <Formik
        validationSchema={schema}
        initialValues={this.initialValues}
        onSubmit={this.handleFormSubmit}
      >
        {({ values, isSubmitting, errors, touched }) => (
          <Form className='grid grid-cols-12 sm:gap-x-8 gap-y-2'>
            <ValidatedFieldWithLabel
              className='col-span-12 sm:col-span-6'
              labelText='Name'
              type='text'
              placeholder='Name'
              name='name'
              disabled={this.props.isEdit}
              touched={touched}
              errors={errors}
            />

            <FieldArray
              name='cost'
              render={(helpers) => (
                <div className='col-span-12 grid grid-cols-12 gap-x-2 sm:gap-x-8 gap-y-2 items-center'>
                  <SectionSubHeader className='col-span-12'>
                    Price per unit
                  </SectionSubHeader>
                  {values.cost.map((x, i) => (
                    <Fragment key={i}>
                      <ValidatedFieldWithLabel
                        className='col-span-12 sm:col-span-6'
                        name={`cost[${i}].cost` as any}
                        placeholder='Cost'
                        type='number'
                        labelText='Cost ($)'
                        touched={touched}
                        errors={errors}
                        touchedFn={(t) =>
                          t?.cost
                            ? (t.cost[i] as FormikTouched<{ cost: number }>)
                                ?.cost
                            : undefined
                        }
                        errorFn={(t) =>
                          t?.cost
                            ? (t.cost[i] as FormikErrors<{ cost: number }>)
                                ?.cost
                            : undefined
                        }
                      />

                      <ValidatedEnumDropdownWithLabel
                        className='col-span-8 sm:col-span-4 lg:col-span-5'
                        name={`cost[${i}].unit` as any}
                        placeholder='Unit'
                        labelText='Unit'
                        touched={touched}
                        errors={errors}
                        keys={Object.keys(Units)}
                        touchedFn={(t) =>
                          t?.cost
                            ? (t.cost[i] as FormikTouched<{ unit: string }>)
                                ?.unit
                            : undefined
                        }
                        errorFn={(t) =>
                          t?.cost
                            ? (t.cost[i] as FormikErrors<{ unit: string }>)
                                ?.unit
                            : undefined
                        }
                      />

                      <Button
                        className='col-span-4 sm:col-span-2 lg:col-span-1'
                        color='danger'
                        type='button'
                        onClick={() => helpers.remove(i)}
                      >
                        <TrashIcon />
                      </Button>
                    </Fragment>
                  ))}
                  <Button
                    className='col-span-12 sm:col-span-4 lg:col-span-2'
                    color='secondary'
                    type='button'
                    onClick={() =>
                      helpers.insert(values.cost.length, {
                        cost: 0,
                        unit: Units.Unit,
                      })
                    }
                  >
                    Add a price
                  </Button>
                </div>
              )}
            />

            <Button
              className='col-span-12 sm:col-span-4 lg:col-span-2'
              color='primary'
              type='submit'
              disabled={isSubmitting}
            >
              {this.props.isEdit ? 'Save' : 'Create'}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(IngredientForm);
