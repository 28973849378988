import { Page } from '../Page';

const areas: { [key: string]: Page } = {
  Administration: {
    path: '/site/admin',
    name: 'Administration',
  },
  Dashboards: {
    path: '/site/dashboards',
    name: 'Dashboards',
  },
  Tools: {
    path: '/site/tools',
    name: 'Tools',
  },
  Recipes: {
    path: '/site/recipes',
    name: 'Recipes',
  },
};

export default areas;
