import cx from 'classnames';
import React, { Component } from 'react';

import Label from '../Typography/Label';
import ValidatedField, { ValidatedFieldProps } from './ValidatedField';

type ValidatedFieldWithLabelProps<T> = ValidatedFieldProps<T> & {
  labelText: string;
};

export default class ValidatedFieldWithLabel<T> extends Component<
  ValidatedFieldWithLabelProps<T>
> {
  render() {
    const fieldProps = {
      ...this.props,
      className: undefined,
    };

    return (
      <div className={cx(this.props.className, 'w-full')}>
        <Label className='mb-1'>{this.props.labelText}</Label>
        <ValidatedField {...fieldProps}></ValidatedField>
      </div>
    );
  }
}
