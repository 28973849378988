import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import LoadGameComponent from '../../../../components/LoadGameComponent';
import StartGameComponent from '../../../../components/StartGameComponent';
import PageHeader from '../../../../components/Typography/PageHeader';
import SectionHeader from '../../../../components/Typography/SectionHeader';
import database from '../../../../models/Database';
import { DominoGame } from '../../../../models/games/DominoGame';
import { GameWrapper } from '../../../../models/games/GameWrapper';
import DominoSettingsComponent from './DominoSettingsComponent';

export class DominoSetup extends Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {};

    this.onStart = this.onStart.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.loadGames = this.loadGames.bind(this);
  }

  loadGames() {
    return database.dominoGames.orderBy('created').toArray();
  }

  onLoad(data: GameWrapper<DominoGame>) {
    this.props.history.push(`/site/tools/domino/${data.id}/`);
  }

  async onStart(players: string[], settings: DominoGame) {
    const game: GameWrapper<DominoGame> = {
      players,
      created: new Date(),
      modified: new Date(),
      game: settings,
    };

    game.id = await database.dominoGames.add(game);
    this.onLoad(game);
  }

  render() {
    return (
      <>
        <PageHeader>Domino Score Tracker</PageHeader>
        <div className='grid lg:grid-cols-2 gap-8'>
          <div className='col-12 col-lg-6'>
            <SectionHeader>Start a new game</SectionHeader>
            <StartGameComponent
              onLoad={this.onStart}
              settingsComponent={DominoSettingsComponent}
            />
          </div>
          <div className='col-12 col-lg-6'>
            <SectionHeader>Load an existing game</SectionHeader>
            <LoadGameComponent<DominoGame> onLoad={this.onLoad} loadGames={this.loadGames} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DominoSetup);
