import { Component } from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';
import Card from './Card';

type ReloadGameTipsProps = ClassNameProps & {
  name: string;
};

export class ReloadGameTips extends Component<ReloadGameTipsProps> {
  render() {
    return (
      <Card className={this.props.className} title='Lose your spot?'>
        Don't worry! The {this.props.name} saves your games as you enter
        information to your local device's storage. So, if you accidentally
        close out of the tab or refresh the page, you should be able to reload
        your progress on the {this.props.name}'s home page!
      </Card>
    );
  }
}

export default ReloadGameTips;
