import { Component } from 'react';
import Label from '../Typography/Label';

import ValidatedEnumDropdown, {
  ValidatedEnumDropdownProps,
} from './ValidatedEnumDropdown';

type ValidatedEnumDropdownWithLabelProps<T> = ValidatedEnumDropdownProps<T> & {
  labelText: string;
};

export default class ValidatedEnumDropdownWithLabel<T> extends Component<
  ValidatedEnumDropdownWithLabelProps<T>
> {
  render() {
    const fieldProps = {
      ...this.props,
      className: undefined,
    };

    return (
      <div className={this.props.className}>
        <Label className='mb-1'>{this.props.labelText}</Label>
        <ValidatedEnumDropdown {...fieldProps}></ValidatedEnumDropdown>
      </div>
    );
  }
}
