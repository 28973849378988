import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { LoginResponse, loginToken } from './services/AuthService';
import { HomePage } from './views/HomePage';
import PublicView from './views/pub/PublicView';
import SiteView from './views/site/SiteView';

interface AppProps {}

interface AppState {
  refreshToken?: string | null;
  userInfo?: LoginResponse;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    const refreshToken = localStorage.getItem('refreshToken');
    this.state = { refreshToken };

    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    if (!this.state.refreshToken) {
      return;
    }

    loginToken(this.state.refreshToken).then((response) => {
      const cachedInfo = localStorage.getItem('userInfo');
      if (response.ok && response.body) {
        this.onLogin(response.body as LoginResponse);
      } else if (response.status !== 0) {
        this.onLogout();
      } else if (cachedInfo) {
        this.onLogin(JSON.parse(cachedInfo));
      }
    });
  }

  onLogin(response: LoginResponse) {
    this.setState({
      userInfo: response,
      refreshToken: response.refreshToken,
    });

    localStorage.setItem('refreshToken', response.refreshToken!);
    localStorage.setItem(
      'userInfo',
      JSON.stringify({
        ...response,
        email: 'Offline User',
        firstName: 'Offline',
        lastName: 'User',
        accessToken: 'dummy_token',
      })
    );
  }

  onLogout() {
    this.setState({ refreshToken: null, userInfo: undefined });

    localStorage.removeItem('refreshToken');
  }

  render() {
    const homePage = (
      <HomePage
        refreshToken={this.state.refreshToken}
        accessToken={this.state.userInfo?.accessToken}
      />
    );

    return (
      <>
        {this.state.refreshToken && !this.state.userInfo?.accessToken ? (
          homePage
        ) : (
          <Router>
            <Switch>
              <Route path='/site'>
                <SiteView
                  userInfo={this.state.userInfo}
                  onLogout={this.onLogout}
                ></SiteView>
              </Route>
              <Route path='/pub'>
                <PublicView
                  onLogin={this.onLogin}
                  token={this.state.refreshToken}
                ></PublicView>
              </Route>
              <Route exact path='/'>
                {homePage}
              </Route>
            </Switch>
          </Router>
        )}
        <Toaster />
      </>
    );
  }
}

export default App;
