import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import EnumDropdownFieldWithLabel from '../../../components/FormFields/EnumDropdownFieldWithLabel';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel';
import ToggleFieldWithLabel from '../../../components/FormFields/ToggleFieldWithLabel';
import Spinner from '../../../components/Spinner';
import PageHeader from '../../../components/Typography/PageHeader';
import { Roles } from '../../../models/enums/Roles';
import AuthenticatedUserProps from '../../../models/props/AuthenticatedUserProps';
import { errorToast, successToast } from '../../../services/ToastService';
import {
  getUser,
  GetUserResponse,
  setUserDisabled,
  setUserRole,
  unlockUser,
} from '../../../services/UserService';

type UserComponentProps = RouteComponentProps<{
  id: string;
}> &
  AuthenticatedUserProps;

type UserComponentState = {
  user?: GetUserResponse;
};

export class User extends Component<UserComponentProps, UserComponentState> {
  constructor(props: UserComponentProps) {
    super(props);

    this.state = {};
    this.roleChange = this.roleChange.bind(this);
    this.unlock = this.unlock.bind(this);
    this.disabledChange = this.disabledChange.bind(this);
  }

  async componentDidMount() {
    const response = await getUser(
      this.props.match.params.id,
      this.props.userInfo.accessToken
    );
    if (!response.body || response.status === 404) {
      this.props.history.push('/site/admin/');
      return;
    }

    this.setState({ user: response.body as GetUserResponse });
  }

  async roleChange(ev: ChangeEvent<HTMLSelectElement>) {
    if (!this.state.user) {
      return;
    }

    const oldValue = this.state.user.role;
    const value = parseInt(ev.target.value) as Roles;
    this.setState({ user: { ...this.state.user, role: Roles[value] } });

    const response = await setUserRole(
      this.state.user.id,
      value,
      this.props.userInfo.accessToken
    );
    if (response.ok) {
      successToast('User role updated successfully!');
    } else {
      errorToast(
        'Hmmm... something went wrong when updating the user role. Try again later.',
        'user-role'
      );
      this.setState({ user: { ...this.state.user, role: oldValue } });
    }
  }

  async disabledChange() {
    if (!this.state.user) {
      return;
    }

    const oldValue = this.state.user.disabled;
    const value = !oldValue;
    this.setState({ user: { ...this.state.user, disabled: value } });

    const response = await setUserDisabled(
      this.state.user.id,
      value,
      this.props.userInfo.accessToken
    );
    if (response.ok) {
      successToast('User activation updated successfully!');
    } else {
      errorToast(
        'Hmmm... something went wrong when updating the user activation. Try again later.',
        'user-role'
      );
      this.setState({ user: { ...this.state.user, disabled: oldValue } });
    }
  }

  async unlock() {
    if (!this.state.user) {
      return;
    }

    const oldValue = this.state.user.lockedOut;
    const value = !oldValue;
    this.setState({ user: { ...this.state.user, lockedOut: value } });

    const response = await unlockUser(
      this.state.user.id,
      this.props.userInfo.accessToken
    );
    if (response.ok) {
      successToast('Unlocked user successfully!');
    } else {
      errorToast(
        'Hmmm... something went wrong when unlocking the user. Try again later.',
        'user-unlock'
      );
      this.setState({ user: { ...this.state.user, lockedOut: oldValue } });
    }
  }

  render() {
    const title = this.state.user
      ? `${this.state.user.firstName} ${this.state.user.lastName}`
      : 'Loading user data...';

    const user = this.state.user;
    const created = user ? new Date(user.created) : new Date();
    const modified = user ? new Date(user.modified) : new Date();
    const isCurrentUser = user?.id === this.props.userInfo.id;

    return (
      <>
        <PageHeader>{title}</PageHeader>
        {this.state.user ? (
          <div className='grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4'>
            <FieldWithLabel
              type='email'
              labelText='Email address'
              disabled
              value={user?.email}
            />
            <FieldWithLabel
              type='text'
              labelText='First name'
              disabled
              value={user?.firstName}
            />
            <FieldWithLabel
              type='text'
              labelText='Last name'
              disabled
              value={user?.lastName}
            />
            <FieldWithLabel
              type='text'
              labelText='Created'
              disabled
              value={created.toLocaleString()}
            />
            <FieldWithLabel
              type='text'
              labelText='Modified'
              disabled
              value={modified.toLocaleString()}
            />
            {isCurrentUser ? (
              <FieldWithLabel
                type='text'
                labelText='Role'
                disabled
                value={(user?.role as any) ?? Roles[0]}
              />
            ) : (
              <EnumDropdownFieldWithLabel
                labelText='Role'
                value={Roles[user?.role as keyof typeof Roles]}
                keys={Object.keys(Roles)}
                onChange={this.roleChange}
              />
            )}
            <div>
              <ToggleFieldWithLabel
                labelText='Locked out?'
                value={user?.lockedOut}
                onChange={this.unlock}
                disabled={!user?.lockedOut}
              />
              <ToggleFieldWithLabel
                labelText='Disabled?'
                value={user?.disabled}
                onChange={this.disabledChange}
                disabled={isCurrentUser}
              />
              <ToggleFieldWithLabel
                labelText='Email confirmed?'
                value={user?.emailConfirmed}
                disabled={true}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </>
    );
  }
}

export default withRouter(User);
