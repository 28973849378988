import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Page } from '../models/Page';
import NavbarLink from './NavbarLink';

type PageWrapperProps = RouteComponentProps & {
  name: string;
  pages: Page[];
};

class PageWrapper extends Component<PageWrapperProps> {
  componentDidMount() {
    if (
      !this.props.pages.find((x) =>
        this.props.location.pathname.startsWith(x.path)
      ) &&
      this.props.pages.length > 0
    ) {
      this.props.history.push(this.props.pages[0].path);
    }
  }

  render() {
    return (
      <div className='flex flex-col flex-grow'>
        <nav className='bg-blue-300'>
          <div className='container px-4 py-4 mx-auto flex gap-4 sm:items-center flex-col sm:flex-row'>
            <h5 className='text-center sm:text-left font-semibold'>{this.props.name}</h5>
            {this.props.pages.map((x) => (
              <NavbarLink
                className='text-center sm:text-left'
                color='gray-500'
                activeColor='white'
                to={x.path}
                key={x.name}
              >
                {x.name}
              </NavbarLink>
            ))}
          </div>
        </nav>
        <main className='container px-4 md:px-8 pt-4 pb-52 mx-auto bg-white flex-grow shadow mt-2 rounded-t-lg'>
          {this.props.children}
        </main>
      </div>
    );
  }
}

export default withRouter(PageWrapper);
