export enum IngredientTypes {
  Eggs,
  Dairy,
  Fat,
  Oil,
  Fruit,
  Grain,
  Nut,
  BakingProducts,
  Herb,
  Spice,
  Meat,
  Fish,
  Pasta,
  Rice,
  Vegetable,
  Other,
}
