import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';

import Button from '../../../../../components/Button';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import Spinner from '../../../../../components/Spinner';
import SectionHeader from '../../../../../components/Typography/SectionHeader';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import { ClassNameProps } from '../../../../../models/props/ClassNameProps';
import {
  GetRecipeStepResponse,
  getRecipeSteps,
  removeStepFromRecipe,
} from '../../../../../services/RecipeService';
import RecipeStepsForm from './RecipeStepsForm';

type RecipeStepsProps = ClassNameProps &
  AuthenticatedUserProps & {
    edit: boolean;
    recipeId: string;
  };

const RecipeSteps: React.FC<RecipeStepsProps> = (props) => {
  const [toRemove, setToRemove] = useState<GetRecipeStepResponse | null>(null);
  const [steps, setSteps] = useState<GetRecipeStepResponse[] | null>(null);

  const loadSteps = useCallback(async () => {
    const response = await getRecipeSteps(
      props.recipeId,
      props.userInfo.accessToken
    );

    if (!response.ok) {
      return;
    }

    setSteps(response.body as GetRecipeStepResponse[]);
  }, [props.recipeId, props.userInfo.accessToken]);

  const showRemoveModal = (step: GetRecipeStepResponse) => {
    setToRemove(step);
  };

  const hideRemoveModal = () => {
    setToRemove(null);
  };

  const removeStep = async (order: number) => {
    await removeStepFromRecipe(
      props.recipeId,
      order,
      props.userInfo.accessToken
    );
    await loadSteps();
    hideRemoveModal();
  };

  useEffect(() => {
    loadSteps();
  }, [loadSteps]);

  return (
    <div>
      <SectionHeader>Steps</SectionHeader>
      {steps ? (
        steps.map((x, i) => (
          <div key={i} className='flex flex-col gap-4 mb-8 md:flex-row'>
            <div className='flex justify-between items-end gap-2 md:flex-col md:justify-start md:items-stretch'>
              <div className='w-12 h-12 rounded-full bg-gray-300 text-lg font-bold flex justify-center items-center'>
                {x.order}
              </div>
              {props.edit && (
                <Button
                  sm
                  color='danger'
                  className='flex-shrink-0'
                  onClick={() => showRemoveModal(x)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
            <div className='col-span-2 pt-2 pr-2 border-t pb-8'>
              {x.instruction}
            </div>
          </div>
        ))
      ) : (
        <Spinner />
      )}
      {props.edit && (
        <RecipeStepsForm className='mt-8' onAdd={loadSteps} {...props} />
      )}
      {toRemove && (
        <ConfirmModal
          title={'Remove Step'}
          text={`Are you sure you want to remove step ${toRemove.order} from the recipe?`}
          confirmText='Delete'
          onConfirm={() => removeStep(toRemove.order)}
          onCancel={hideRemoveModal}
        />
      )}
    </div>
  );
};

export default RecipeSteps;
