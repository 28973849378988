import cx from 'classnames';
import React from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';

const SectionHeader: React.FC<ClassNameProps> = (props) => {
  return (
    <h2 className={cx(props.className, 'text-xl font-semibold mb-3')}>
      {props.children}
    </h2>
  );
};

export default SectionHeader;
