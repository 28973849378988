import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Button from '../../../../components/Button';

import IngredientForm from '../../../../components/IngredientForm';
import Spinner from '../../../../components/Spinner';
import PageHeader from '../../../../components/Typography/PageHeader';
import AuthenticatedUserProps from '../../../../models/props/AuthenticatedUserProps';
import {
  disableIngredient,
  editIngredient,
  enableIngredient,
  getIngredient,
  GetIngredientResponse,
} from '../../../../services/RecipeService';
import { successToast } from '../../../../services/ToastService';

type IngredientDetailViewRouteParams = { id: string };

type IngredientDetailViewProps = AuthenticatedUserProps;

const IngredientDetailView: React.FC<IngredientDetailViewProps> = (props) => {
  const params = useParams<IngredientDetailViewRouteParams>();
  const history = useHistory();
  const id = params.id;
  const [ingredient, setIngredient] = useState<GetIngredientResponse | null>(
    null
  );

  useEffect(() => {
    const fetchIngredient = async () => {
      const response = await getIngredient(id, props.userInfo.accessToken);
      if (response.ok) {
        setIngredient(response.body as GetIngredientResponse);
      }
    };

    fetchIngredient();
  }, [id, props.userInfo.accessToken]);

  const toggleDisable = async () => {
    if (!ingredient?.id) {
      return;
    }

    const response = ingredient.disabled
      ? await enableIngredient(ingredient.id!, props.userInfo.accessToken)
      : await disableIngredient(ingredient.id!, props.userInfo.accessToken);
    if (response.ok) {
      if (ingredient.disabled) {
        successToast(`${ingredient.name} was successfully enabled!`);
      } else {
        successToast(`${ingredient.name} was successfully disabled!`);
      }

      history.push('..');
    }
  };

  const disableButton = () => {
    if (!ingredient?.canDisable) {
      return <></>;
    }

    if (ingredient.disabled) {
      return (
        <Button color='success' onClick={toggleDisable}>
          Enable
        </Button>
      );
    }

    return (
      <Button color='danger' onClick={toggleDisable}>
        Disable
      </Button>
    );
  };

  return (
    <div>
      <div className='flex justify-between items-start mb-2'>
        <PageHeader>Edit Ingredient</PageHeader>
        {disableButton()}
      </div>
      {ingredient ? (
        <IngredientForm
          onSubmit={editIngredient}
          ingredient={ingredient}
          isEdit={true}
          {...props}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default IngredientDetailView;
