import React from 'react';

type ModalProps = {
  onClose: () => void;
};

const Modal: React.FC<ModalProps> = (props) => {
  return (
    <div
      className='animate-fadeIn fixed w-screen h-screen bg-gray-500 bg-opacity-50 inset-0 flex justify-center items-center p-4'
      onClick={props.onClose}
    >
      <div
        className='animate-slideIn shadow bg-white px-8 py-4 rounded-lg w-full sm:w-1/2 lg:w-1/3'
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
