import { Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../components/Button';
import ValidatedFieldWithLabel from '../../components/FormFields/ValidatedFieldWithLabel';
import PageHeader from '../../components/Typography/PageHeader';
import VerticalButtonGroup from '../../components/VerticalButtonGroup';
import LoginHandlerProps from '../../models/props/LoginHandlerProps';
import { login, LoginResponse } from '../../services/AuthService';

type LoginProps = RouteComponentProps<{}> & LoginHandlerProps;

interface LoginState {
  incorrect: boolean;
}

interface LoginValues {
  email: string;
  password: string;
}

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = { incorrect: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async handleFormSubmit(
    values: LoginValues,
    helpers: FormikHelpers<LoginValues>
  ) {
    const result = await login(values.email, values.password);
    helpers.setSubmitting(false);

    if (result.ok && result.body) {
      this.props.onLogin(result.body as LoginResponse);
    } else if (result.status !== 0) {
      this.setState({ incorrect: true });
    }
  }

  render() {
    const initialValues: LoginValues = {
      email: '',
      password: '',
    };

    const schema = Yup.object().shape({
      email: Yup.string()
        .email('This field must be a valid email address.')
        .required('This field is required'),
      password: Yup.string()
        .required('This field is required')
        .min(8, 'Password must be at least 8 characters long'),
    });

    return (
      <>
        <PageHeader>Login to your account</PageHeader>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleFormSubmit}
          initialValues={initialValues}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form>
                <ValidatedFieldWithLabel
                  labelText='Email address'
                  type='email'
                  placeholder='Email'
                  name='email'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <ValidatedFieldWithLabel
                  labelText='Password'
                  type='password'
                  placeholder='Password'
                  name='password'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <VerticalButtonGroup>
                  <Button type='submit' disabled={isSubmitting} color='primary'>
                    Log in
                  </Button>
                  <Button to='/pub/forgot-password/' color='secondary'>
                    Forgot password?
                  </Button>
                  <Button to='/pub/register/' color='secondary'>
                    Register
                  </Button>
                </VerticalButtonGroup>

                {this.state.incorrect ? (
                  <p className='my-2 text-red-500'>
                    Incorrect email or password.
                  </p>
                ) : (
                  <></>
                )}
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withRouter(Login);
