import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import LogoutHandlerProps from '../models/props/AuthenticatedUserProps';
import { LoginResponse } from '../services/AuthService';
import Button from './Button';
import NavbarLink from './NavbarLink';

type SiteWrapperProps = LogoutHandlerProps & {
  userInfo: LoginResponse;
};

const SiteWrapper: React.FC<SiteWrapperProps> = (props) => {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  return (
    <div className='flex flex-col min-h-screen'>
      <nav className='bg-white shadow-lg'>
        <div className='container px-4 py-4 flex gap-4 mx-auto items-center'>
          <button
            className='lg:hidden px-4 py-2'
            onClick={() => setOpen(!open)}
            type='button'
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <Link className='text-xl font-semibold hidden sm:block' to='/site'>
            bscox.com
          </Link>
          <div
            className='flex gap-2 justify-between flex-grow items-center'
            id='navbar-links'
          >
            <div className='hidden lg:flex gap-2'>
              {props.userInfo.areaAccess.map((x) => (
                <NavbarLink
                  color='gray-400'
                  activeColor='blue-300'
                  to={x.path}
                  key={x.name}
                >
                  {x.name}
                </NavbarLink>
              ))}
            </div>
            <span className='flex-grow' />
            <span className='flex gap-4 items-center'>
              <span>{props.userInfo.email}</span>
              <Button
                className='hidden sm:block'
                to='/pub/login/'
                color='secondary'
                onClick={props.onLogout}
              >
                Logout
              </Button>
            </span>
          </div>
        </div>
      </nav>
      <div className='bg-gray-200 flex-grow relative flex flex-col'>
        <div
          className={cx(
            'absolute w-52 flex flex-col lg:hidden bg-white h-full shadow -left-52 transform transition-transform gap-2 px-4 py-4',
            {
              'translate-x-52': open,
            }
          )}
        >
          {props.userInfo.areaAccess.map((x) => (
            <Link
              key={x.name}
              className='py-2 rounded-md transition-colors hover:bg-blue-300 px-4'
              to={x.path}
              onClick={close}
            >
              {x.name}
            </Link>
          ))}
          <Button
            className='block sm:hidden'
            to='/pub/login/'
            color='secondary'
            onClick={props.onLogout}
          >
            Logout
          </Button>
        </div>
        <div className='flex-grow flex flex-col' onClick={close}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SiteWrapper;
