import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import PageWrapper from '../../../components/PageWrapper';
import { Page } from '../../../models/Page';
import AuthenticatedUserProps from '../../../models/props/AuthenticatedUserProps';
import User from './User';
import UserList from './UserList';

type AdministrationViewProps = RouteComponentProps<{}> & AuthenticatedUserProps;

export class AdministrationView extends Component<AdministrationViewProps> {
  render() {
    const pages: Page[] = [
      {
        name: 'Users',
        path: `${this.props.match.url}`
      }
    ];

    return (
      <PageWrapper pages={pages} name='Administration'>
        <Switch>
          <Route path={`${this.props.match.url}/:id`}>
            <User {...this.props} />
          </Route>
          <Route exact path={`${this.props.match.url}`}>
            <UserList {...this.props} />
          </Route>
        </Switch>
      </PageWrapper>
    );
  }
}

export default withRouter(AdministrationView);
