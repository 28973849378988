import cx from 'classnames';
import React from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';

const PageHeader: React.FC<ClassNameProps> = (props) => {
  return (
    <h1 className={cx('text-2xl font-semibold mb-4', props.className)}>
      {props.children}
    </h1>
  );
};

export default PageHeader;
