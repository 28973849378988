import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import CenteredPageWrapper from '../components/CenteredPageWrapper';
import Spinner from '../components/Spinner';

interface HomePageProps {
  refreshToken?: string | null;
  accessToken?: string | null;
}

export class HomePage extends Component<HomePageProps> {
  render() {
    return this.props.refreshToken ? (
      this.props.accessToken ? (
        <Redirect to='/site/' />
      ) : (
        <CenteredPageWrapper>
          <h3 className='text-center'>Logging you in...</h3>
          <div className='d-flex justify-content-center'>
            <Spinner />
          </div>
        </CenteredPageWrapper>
      )
    ) : (
      <Redirect to='/pub/login/' />
    );
  }
}

export default HomePage;
