import Dexie from 'dexie';

import { DominoGame } from './games/DominoGame';
import { GameWrapper } from './games/GameWrapper';

class Database extends Dexie {
  dominoGames: Dexie.Table<GameWrapper<DominoGame>, number>;

  constructor(dbName: string) {
    super(dbName);

    this.version(1).stores({
      dominoGames: '++id,created,modified',
    });

    this.dominoGames = this.table('dominoGames');
  }
}

var database = new Database('gamesDb');
database.open().catch((err) => console.error('Failed to open database: ', err));

export default database;
