import React, { ChangeEventHandler } from 'react';
import * as Yup from 'yup';

import ToggleFieldWithLabel from '../../../../../components/FormFields/ToggleFieldWithLabel';
import HoverEditField from '../../../../../components/HoverEditField/HoverEditField';
import PageHeader from '../../../../../components/Typography/PageHeader';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import { ClassNameProps } from '../../../../../models/props/ClassNameProps';
import {
  GetRecipeResponse,
  setDescription,
  setName,
} from '../../../../../services/RecipeService';

type RecipeHeaderCardProps = AuthenticatedUserProps &
  ClassNameProps & {
    id: string;
    recipe: GetRecipeResponse;
    edit?: boolean;
    toggleEdit: ChangeEventHandler;
  };

const RecipeHeaderCard: React.FC<RecipeHeaderCardProps> = (props) => {
  return props.edit ? (
    <div className={props.className}>
      <div className='flex items-start gap-4'>
        <HoverEditField
          classname='flex-grow'
          value={props.recipe.name}
          styleAsClass='font-semibold text-xl'
          schema={Yup.string().min(3, 'Must be at least 3 characters long.')}
          onUpdate={(value: string) =>
            setName(props.id, value, props.userInfo.accessToken)
          }
        />
        <ToggleFieldWithLabel
          labelText={'Edit'}
          onChange={props.toggleEdit}
          value={props.edit}
        />
      </div>
      <HoverEditField
        value={props.recipe.description}
        textarea
        rows={8}
        schema={Yup.string().min(3, 'Must be at least 3 characters long.')}
        onUpdate={(value: string) =>
          setDescription(props.id, value, props.userInfo.accessToken)
        }
      />
    </div>
  ) : (
    <div className={props.className}>
      <div className='flex items-start gap-4'>
        <PageHeader className='flex-grow'>{props.recipe.name}</PageHeader>
        <ToggleFieldWithLabel
          labelText={'Edit'}
          onChange={props.toggleEdit}
          value={props.edit}
        />
      </div>
      <p className='whitespace-pre-wrap'>{props.recipe.description}</p>
    </div>
  );
};

export default RecipeHeaderCard;
