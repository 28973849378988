import cx from 'classnames';
import React, { Component } from 'react';

import { PaginatedListResponse } from '../models/PaginatedListResponse';
import { HttpResponse } from '../services/HttpService';
import Button from './Button';
import Spinner from './Spinner';

interface Column<T> {
  name: string;
  get: (elem: T) => string | number;
}

export type TableProps<T> = {
  accessToken: string;
  columns: Column<T>[];
  load: (
    page: number,
    token: string
  ) => Promise<HttpResponse<PaginatedListResponse<T>>>;
  rowClick?: (row: T) => void;
  disabled: (row: T) => boolean;
};

type TableState<T> = {
  loading: boolean;
  data?: PaginatedListResponse<T>;
};

export class Table<T> extends Component<TableProps<T>, TableState<T>> {
  constructor(props: TableProps<T>) {
    super(props);

    this.state = { loading: true };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  async componentDidMount() {
    await this.loadPage(0);
  }

  async next() {
    const page = this.state.data?.page ?? 0;
    await this.loadPage(page + 1);
  }

  async previous() {
    const page = this.state.data?.page ?? 1;
    await this.loadPage(page - 1);
  }

  async loadPage(page: number) {
    this.setState({ loading: true });
    const response = await this.props.load(page, this.props.accessToken);
    if (!response.body || !response.ok) {
      return;
    }

    this.setState({
      data: response.body as PaginatedListResponse<T>,
      loading: false,
    });
  }

  render() {
    return this.state.data ? (
      <div>
        <div className='overflow-x-auto'>
          <table className='table-auto border-collapse w-full shadow rounded-xl'>
            <thead>
              <tr className='border-b bg-gray-100'>
                {this.props.columns.map((x) => (
                  <th
                    key={x.name}
                    scope='col'
                    className='text-left px-4 py-2 text-gray-500 font-light uppercase text-sm first:rounded-tl-xl last:rounded-tr-xl'
                  >
                    {x.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.data.items.map((row, i, arr) => (
                <tr
                  key={i}
                  onClick={() =>
                    this.props.rowClick && this.props.rowClick(row)
                  }
                  className={cx('bg-white hover:bg-gray-200 cursor-pointer', {
                    'bg-gray-100': i % 2 === 1,
                    'bg-gray-300 text-gray-600': this.props.disabled(row),
                  })}
                >
                  {this.props.columns.map((col, j) => (
                    <td
                      key={col.name}
                      className={cx('px-4 py-2', {
                        'first:rounded-bl-xl last:rounded-br-xl':
                          arr.length === i + 1,
                        'font-semibold text-black':
                          j === 0 && !this.props.disabled(row),
                        'text-gray-500': j !== 0,
                      })}
                    >
                      {col.get(row)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex flex-col items-end gap-2 mt-2 text-sm text-gray-500'>
          <span>
            Showing {this.state.data.items.length} out of{' '}
            {this.state.data.totalItems} results
          </span>
          <span className='flex gap-2 items-center'>
            <Button
              disabled={!this.state.data.hasPreviousPage}
              onClick={this.previous}
              color='secondary'
              sm
            >
              &lt;
            </Button>
            <span>
              Page {this.state.data.page + 1} / {this.state.data.totalPages}
            </span>
            <Button
              disabled={!this.state.data.hasNextPage}
              onClick={this.next}
              color='secondary'
              sm
            >
              &gt;
            </Button>
          </span>
        </div>
        {this.state.loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <Spinner />
    );
  }
}

export default Table;
