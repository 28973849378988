import { Form, Formik, FormikHelpers } from 'formik';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';

import Button from '../../components/Button';
import ValidatedFieldWithLabel from '../../components/FormFields/ValidatedFieldWithLabel';
import PageHeader from '../../components/Typography/PageHeader';
import VerticalButtonGroup from '../../components/VerticalButtonGroup';
import { getPasswordRequirements, register } from '../../services/AuthService';
import { successToast } from '../../services/ToastService';

type RegisterProps = RouteComponentProps<{}>;

interface RegisterState {
  requirements: RequiredStringSchema<any>;
  emailExists: boolean;
}

interface RegisterFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  confirmPassword: string;
}

class Register extends Component<RegisterProps, RegisterState> {
  constructor(props: RegisterProps) {
    super(props);

    this.state = { requirements: Yup.string(), emailExists: false };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async componentDidMount() {
    const requirements = await getPasswordRequirements();
    this.setState({ requirements });
  }

  async handleFormSubmit(
    values: RegisterFormValues,
    helpers: FormikHelpers<RegisterFormValues>
  ) {
    const result = await register(
      values.email,
      values.password,
      values.firstName,
      values.lastName
    );

    if (result.ok) {
      this.props.history.push('/pub/login/');
      successToast(
        "Your account has been successfully registered. You'll need to confirm your email before you're able to log in. Check your email for a confirmation link!"
      );
    } else if (result.status === 403) {
      this.setState({ emailExists: true });
    }
  }

  render() {
    const initialValues: RegisterFormValues = {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
    };

    const schema = Yup.object().shape({
      email: Yup.string()
        .required('This field is required')
        .email('This field must be a valid email address.'),
      password: this.state.requirements.required('This field is required.'),
      confirmPassword: Yup.string()
        .required('This field is required.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      firstName: Yup.string().required('This field is required.'),
      lastName: Yup.string().required('This field is required.'),
    });

    return (
      <>
        <PageHeader>Register</PageHeader>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={this.handleFormSubmit}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form>
                <ValidatedFieldWithLabel
                  labelText='Email address'
                  type='email'
                  placeholder='Email'
                  name='email'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <ValidatedFieldWithLabel
                  labelText='First name'
                  type='text'
                  placeholder='First name'
                  name='firstName'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <ValidatedFieldWithLabel
                  labelText='Last name'
                  type='text'
                  placeholder='Last name'
                  name='lastName'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <ValidatedFieldWithLabel
                  labelText='Password'
                  type='password'
                  placeholder='Password'
                  name='password'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <ValidatedFieldWithLabel
                  labelText='Confirm password'
                  type='password'
                  placeholder='Confirm password'
                  name='confirmPassword'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <VerticalButtonGroup>
                  <Button type='submit' disabled={isSubmitting} color='primary'>
                    Submit
                  </Button>
                  <Button to='/pub/login/' color='secondary'>
                    Cancel
                  </Button>
                </VerticalButtonGroup>

                {this.state.emailExists ? (
                  <p className='my-2 text-red-500'>
                    A user with that email address already exists.
                  </p>
                ) : (
                  <></>
                )}
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withRouter(Register);
