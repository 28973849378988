import cx from 'classnames';
import _ from 'lodash';
import React, { ChangeEventHandler, Component } from 'react';

import Label from '../Typography/Label';

interface ToggleFieldWithLabelProps {
  placeholder?: string;
  labelText: string;
  className?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLElement>;
}

export class ToggleFieldWithLabel extends Component<ToggleFieldWithLabelProps> {
  id: string;

  constructor(props: ToggleFieldWithLabelProps) {
    super(props);

    this.id = _.uniqueId();
  }

  render() {
    return (
      <div className={cx(this.props.className, 'flex gap-2 items-center')}>
        <input
          id={this.id}
          type='checkbox'
          className='hidden'
          checked={!!this.props.value}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
        />
        <label
          htmlFor={this.id}
          className='relative flex select-none cursor-pointer w-8 h-4'
        >
          <span
            className={cx(
              'absolute left-0 top-p h-full w-full rounded-full transition-colors duration-300 ease-in-out',
              {
                'bg-blue-400': this.props.value && !this.props.disabled,
                'bg-blue-200': this.props.value && this.props.disabled,
                'bg-gray-400 ': !this.props.value && !this.props.disabled,
                'bg-gray-200 ': !this.props.value && this.props.disabled,
              }
            )}
          />
          <span
            className={cx(
              'h-4 w-4 border-2 absolute rounded-full bg-white transition-transform transform duration-300 ease-in-out flex justify-center items-center',
              {
                'border-blue-400 translate-x-4':
                  this.props.value && !this.props.disabled,
                'border-blue-200 translate-x-4':
                  this.props.value && this.props.disabled,
                'border-gray-400': !this.props.value && !this.props.disabled,
                'border-gray-200': !this.props.value && this.props.disabled,
              }
            )}
          />
        </label>
        <Label>{this.props.labelText}</Label>
      </div>
    );
  }
}

export default ToggleFieldWithLabel;
