import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { DishType } from '../models/enums/DishType';
import AuthenticatedUserProps from '../models/props/AuthenticatedUserProps';
import { Recipe } from '../models/recipes/Recipe';
import Button from './Button';
import ValidatedEnumDropdownWithLabel from './FormFields/ValidatedEnumDropdownWithLabel';
import ValidatedFieldWithLabel from './FormFields/ValidatedFieldWithLabel';
import Spinner from './Spinner';

type RecipeFormProps = AuthenticatedUserProps & {
  onSubmit: (recipe: Recipe, token: string) => Promise<void>;
};

interface RecipeFormValues {
  name: string;
  description: string;
  timeRequired: number | string;
  activeTimeRequired: number | string;
  servings: number | string;
  type: DishType;
}

const RecipeForm: React.FC<RecipeFormProps> = (props) => {
  const initialValues: RecipeFormValues = {
    name: '',
    description: '',
    type: DishType.MainCourse,
    timeRequired: '',
    activeTimeRequired: '',
    servings: '',
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    description: Yup.string().required('This field is required'),
    timeRequired: Yup.number()
      .required('This field is required')
      .min(1, 'Time required must be greater than 0 minutes'),
    activeTimeRequired: Yup.number()
      .required('This field is required')
      .min(1, 'Active time required must be greater than 0 minutes'),
    servings: Yup.number()
      .required('This field is required')
      .min(1, 'Servings must be greater than 0'),
    type: Yup.string()
      .oneOf(Object.keys(DishType))
      .required('This field is required'),
  });

  const onSubmit = async (
    value: RecipeFormValues,
    helpers: FormikHelpers<RecipeFormValues>
  ) => {
    const recipe: Recipe = {
      name: value.name,
      description: value.description,
      servings: value.servings as number,
      timeRequired: value.timeRequired as number,
      activeTimeRequired: value.activeTimeRequired as number,
      type: value.type,
    };

    await props.onSubmit(recipe, props.userInfo.accessToken);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className='grid sm:grid-cols-2 gap-x-8 gap-y-2'>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <>
              <ValidatedFieldWithLabel
                labelText='Name'
                type='text'
                placeholder='Name'
                name='name'
                touched={touched}
                errors={errors}
              />

              <ValidatedFieldWithLabel
                className='w-100 mb-2'
                labelText='Servings'
                type='number'
                min={1}
                step={1}
                placeholder='Servings'
                name='servings'
                touched={touched}
                errors={errors}
              />

              <ValidatedFieldWithLabel
                as='textarea'
                rows={10}
                className='row-span-3'
                labelText='Description'
                type='textarea'
                placeholder='Description'
                name='description'
                touched={touched}
                errors={errors}
              />
              <ValidatedFieldWithLabel
                className='w-100 mb-2'
                labelText='Time required (minutes)'
                type='number'
                min={1}
                step={1}
                placeholder='Time required'
                name='timeRequired'
                touched={touched}
                errors={errors}
              />

              <ValidatedFieldWithLabel
                className='w-100 mb-2'
                labelText='Active time required (minutes)'
                type='number'
                min={1}
                step={1}
                placeholder='Active time required'
                name='activeTimeRequired'
                touched={touched}
                errors={errors}
              />

              <ValidatedEnumDropdownWithLabel
                className='w-100 mb-2'
                labelText='Type'
                name='type'
                touched={touched}
                errors={errors}
                keys={Object.keys(DishType)}
              />
            </>
          )}

          <Button
            color='primary'
            type='submit'
            disabled={isSubmitting}
          >
            Next
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RecipeForm;
