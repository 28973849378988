import cx from 'classnames';
import React, { Component } from 'react';

import { ClassNameProps } from '../models/props/ClassNameProps';

export class Spinner extends Component<ClassNameProps> {
  render() {
    return (
      <div
        className={cx(
          'border-8 h-24 w-24 rounded-full border-blue-500 border-b-transparent animate-spin',
          this.props.className
        )}
        role='status'
      ></div>
    );
  }
}

export default Spinner;
