import toast, { ToastOptions } from 'react-hot-toast';

const toastSettings: ToastOptions = {
  duration: 8000,
};

export function errorToast(message: string, id: string) {
  toast.error(message, { ...toastSettings, id });
}

export function successToast(message: string) {
  toast.success(message, toastSettings);
}
