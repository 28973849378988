import React, { Component } from 'react';

import { GameWrapper } from '../models/games/GameWrapper';
import Spinner from './Spinner';

type LoadGameComponentProps<T> = {
  onLoad: (data: GameWrapper<T>) => void;
  loadGames: () => Promise<GameWrapper<T>[]>;
};

type LoadGameComponentState<T> = {
  rows: GameWrapper<T>[];
  loading: boolean;
};

export class LoadGameComponent<T> extends Component<
  LoadGameComponentProps<T>,
  LoadGameComponentState<T>
> {
  constructor(props: LoadGameComponentProps<T>) {
    super(props);

    this.state = { rows: [], loading: true };
  }

  async componentDidMount() {
    const data = await this.props.loadGames();
    this.setState({ rows: data.reverse() as GameWrapper<T>[], loading: false });
  }

  players(row: GameWrapper<T>) {
    return row.players.join(', ');
  }

  render() {
    return this.state.loading ? (
      <Spinner />
    ) : (
      this.state.rows.map((x) => (
        <button
          key={x.id}
          onClick={() => this.props.onLoad(x)}
          className='border rounded-lg w-full text-left px-4 py-2 hover:bg-gray-100'
        >
          <div className='font-semibold'>{this.players(x)}</div>
          <small>{x.created.toLocaleDateString()}</small>
        </button>
      ))
    );
  }
}

export default LoadGameComponent;
