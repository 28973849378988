import { Form, Formik, FormikHelpers } from 'formik';
import React, { Component } from 'react';
import * as Yup from 'yup';

import { errorToast } from '../services/ToastService';
import Button from './Button';
import ValidatedField from './FormFields/ValidatedField';
import { GameSettingsComponentProps } from './GameSettingsComponent';
import SectionSubHeader from './Typography/SectionSubHeader';

type StartGameComponentProps<T> = {
  onLoad: (players: string[], settings: T) => Promise<void>;
  settingsComponent: React.ComponentType<GameSettingsComponentProps<T>>;
};

type StartGameComponentState<T> = {
  players: string[];
  settings?: T;
};

interface StartGameComponentFormValues {
  name: string;
}

export class StartGameComponent<T> extends Component<
  StartGameComponentProps<T>,
  StartGameComponentState<T>
> {
  constructor(props: StartGameComponentProps<T>) {
    super(props);

    this.state = { players: [] };
    this.onStart = this.onStart.bind(this);
    this.onAddPlayer = this.onAddPlayer.bind(this);
    this.onRemovePlayer = this.onRemovePlayer.bind(this);
    this.onSettings = this.onSettings.bind(this);
  }

  onSettings(settings: T) {
    this.setState({ settings });
  }

  onStart() {
    if (this.state.players.length > 0) {
      this.props.onLoad(this.state.players, this.state.settings ?? ({} as T));
    } else {
      errorToast('Games must have at least 1 player to start.', 'player-count');
    }
  }

  onAddPlayer(
    values: StartGameComponentFormValues,
    helpers: FormikHelpers<StartGameComponentFormValues>
  ) {
    this.setState({ players: [...this.state.players, values.name] });
    helpers.setValues({ name: '' });
  }

  onRemovePlayer(index: number) {
    const players = [...this.state.players];
    players.splice(index, 1);
    this.setState({ players });
  }

  render() {
    const initialValues: StartGameComponentFormValues = {
      name: '',
    };

    const schema = Yup.object().shape({
      name: Yup.string().required('This field is required'),
    });

    const Settings = this.props.settingsComponent;

    return (
      <>
        <Settings setCustomSettings={this.onSettings} />

        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={this.onAddPlayer}
        >
          {({ errors, touched }) => {
            return (
              <Form className='flex gap-2 items-start mt-8'>
                <div className='flex-grow'>
                  <ValidatedField
                    type='text'
                    placeholder='Player name'
                    name='name'
                    touched={touched}
                    errors={errors}
                    autocomplete={false}
                  />
                </div>
                <Button type='submit' color='secondary'>
                  Add
                </Button>
              </Form>
            );
          }}
        </Formik>
        {this.state.players.length > 0 && (
          <>
            <SectionSubHeader className='mt-4'>Players</SectionSubHeader>
            <div>
              {this.state.players.map((x, i) => (
                <button
                  key={i}
                  type='button'
                  className='w-full border px-4 py-2 text-left hover:bg-gray-100 first:rounded-t-lg last:rounded-b-lg'
                  onClick={() => this.onRemovePlayer(i)}
                >
                  {x}
                </button>
              ))}
            </div>
          </>
        )}

        <Button color='primary' className='mt-4' onClick={this.onStart}>
          Start
        </Button>
      </>
    );
  }
}

export default StartGameComponent;
