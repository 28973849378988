import cx from 'classnames';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { Component } from 'react';

import { FormikProps } from '../../models/props/FormikProps';

import Caret from '../../assets/icons/caret.svg';

export type ValidatedEnumDropdownProps<T> = FormikProps<T> & {
  placeholder?: string;
  name: keyof T;
  className?: string;
  keys: string[];
  disabled?: boolean;
};

export default class ValidatedEnumDropdown<T> extends Component<
  ValidatedEnumDropdownProps<T>
> {
  values: { key: string; value: number }[];

  constructor(props: ValidatedEnumDropdownProps<T>) {
    super(props);

    this.values = this.props.keys
      .filter((x) => isNaN(parseInt(x, 10)))
      .map((x, i) => ({ key: startCase(x), value: i }));
  }

  render() {
    const touched =
      this.props.touchedFn && this.props.touched
        ? this.props.touchedFn(this.props.touched)
        : this.props.touched[this.props.name];
    const error =
      this.props.errorFn && this.props.errors
        ? this.props.errorFn(this.props.errors)
        : this.props.errors[this.props.name];

    return (
      <>
        <Field
          as='select'
          placeholder={this.props.placeholder}
          name={this.props.name}
          className={cx(
            'w-full px-4 py-2 rounded transition-colors border border-gray-400 outline-none appearance-none bg-right bg-no-repeat bg-origin-content',
            this.props.className,
            {
              'mb-6': !touched || !error,
              'border-green-500': touched && !error,
              'bg-red-100 border-red-500 mb-0': touched && error,
            }
          )}
          style={{ backgroundImage: `url('${Caret}')` }}
        >
          {this.values.map((x, i) => (
            <option key={i} value={x.value}>
              {x.key}
            </option>
          ))}
        </Field>
        <div className='text-red-500 text-sm mt-1'>{touched ? error : ''}</div>
      </>
    );
  }
}
