import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import PageWrapper from '../../../components/PageWrapper';
import { Page } from '../../../models/Page';
import AuthenticatedUserProps from '../../../models/props/AuthenticatedUserProps';
import DominoGameComponent from './DominoScoreTracker/DominoGameComponent';
import DominoSetupState from './DominoScoreTracker/DominoSetup';

type ToolsViewProps = AuthenticatedUserProps & RouteComponentProps;

export class ToolsView extends Component<ToolsViewProps> {
  render() {
    const pages: Page[] = [
      {
        name: 'Domino score tracker',
        path: `${this.props.match.url}/domino/`,
      },
    ];

    return (
      <PageWrapper pages={pages} name='Tools'>
        <Switch>
          <Route exact path={`${this.props.match.url}/domino`}>
            <DominoSetupState {...this.props} />
          </Route>
          <Route path={`${this.props.match.url}/domino/:id`}>
            <DominoGameComponent {...this.props} />
          </Route>
        </Switch>
      </PageWrapper>
    );
  }
}

export default withRouter(ToolsView);
