import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Button from '../../../../../components/Button';
import ValidatedFieldWithLabel from '../../../../../components/FormFields/ValidatedFieldWithLabel';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import { ClassNameProps } from '../../../../../models/props/ClassNameProps';
import { addStepToRecipe } from '../../../../../services/RecipeService';

type RecipeStepsFormProps = ClassNameProps &
  AuthenticatedUserProps & {
    recipeId: string;
    onAdd: () => void;
  };

interface StepFormValues {
  instruction: string;
}

const RecipeStepsForm: React.FC<RecipeStepsFormProps> = (props) => {
  const initialValues: StepFormValues = {
    instruction: '',
  };

  const addStep = async (
    value: StepFormValues,
    helpers: FormikHelpers<StepFormValues>
  ) => {
    console.log('add');
    const response = await addStepToRecipe(
      props.recipeId,
      value.instruction,
      props.userInfo.accessToken
    );

    if (response.ok) {
      helpers.resetForm();
      props.onAdd();
    }
  };

  const schema = Yup.object().shape({
    instruction: Yup.string().required('This field is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={addStep}
      validationSchema={schema}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className={props.className}>
          <ValidatedFieldWithLabel
            labelText='Instructions'
            placeholder='Instructions'
            name='instruction'
            touched={touched}
            errors={errors}
            type='text'
            as='textarea'
            rows={4}
          />

          <Button type='submit' color='primary' disabled={isSubmitting}>
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RecipeStepsForm;
