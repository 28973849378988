import { Component } from 'react';

export class CenteredPageWrapper extends Component {
  render() {
    return (
      <div className='bg-gray-100 sm:bg-gray-200 dark:bg-gray-700 w-screen h-screen flex items-center justify-center'>
        <div className='bg-gray-100 w-full md:w-1/2 lg:w-1/3 sm:px-8 py-4 px-4 sm:mx-8 sm:shadow-md rounded-lg'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default CenteredPageWrapper;
