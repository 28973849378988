import { FieldArray, Form, Formik } from 'formik';
import { Component } from 'react';
import * as Yup from 'yup';

import Button from '../../../../components/Button';
import ValidatedFieldWithLabel from '../../../../components/FormFields/ValidatedFieldWithLabel';
import HorizontalButtonGroup from '../../../../components/HorizontalButtonGroup';
import SectionHeader from '../../../../components/Typography/SectionHeader';

type DominoScoreEntryProps = {
  players: string[];
  existingScores?: number[];
  domino: number;
  setScores: (scores: number[]) => Promise<void>;
  cancelEntry: () => void;
};

interface DominoScoreEntryValues {
  scores: (number | string)[];
}

export class DominoScoreEntry extends Component<DominoScoreEntryProps> {
  constructor(props: DominoScoreEntryProps) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values: DominoScoreEntryValues) {
    this.props.setScores(values.scores as number[]);
  }

  render() {
    const initialValues: DominoScoreEntryValues = {
      scores: this.props.existingScores?.length
        ? this.props.existingScores
        : this.props.players.map(() => ''),
    };

    const schema = Yup.object().shape({
      scores: Yup.array()
        .of(
          Yup.number()
            .min(0, "You can't have a negative score.")
            .required('This field is required')
        )
        .required()
        .min(this.props.players.length)
        .max(this.props.players.length),
    });

    return (
      <div>
        <SectionHeader>
          Score entry for double {this.props.domino}'s
        </SectionHeader>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={schema}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className=''>
              <FieldArray
                name='scores'
                render={() =>
                  this.props.players.map((x, i) => {
                    const name = `scores.${i}`;

                    return (
                      <ValidatedFieldWithLabel
                        key={i}
                        labelText={x}
                        name={name as any}
                        touched={touched}
                        errors={errors}
                        placeholder={`${x}'s score`}
                        type='number'
                        touchedFn={(x: any) => (x.scores ? x.scores[i] : false)}
                        errorFn={(x: any) => (x.scores ? x.scores[i] : '')}
                      />
                    );
                  })
                }
              />
              <HorizontalButtonGroup>
                <Button type='submit' color='primary'>
                  Save scores
                </Button>
                <Button color='secondary' onClick={this.props.cancelEntry}>
                  Cancel
                </Button>
              </HorizontalButtonGroup>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default DominoScoreEntry;
