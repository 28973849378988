import firebase from 'firebase/app';
import 'firebase/performance';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

firebase.initializeApp({
  apiKey: 'AIzaSyCBOvcLDO5XEUH7v0fMVoKkaW2NQWwP6LA',
  authDomain: 'bscox-com.firebaseapp.com',
  projectId: 'bscox-com',
  storageBucket: 'bscox-com.appspot.com',
  messagingSenderId: '282448706060',
  appId: '1:282448706060:web:a7f5adaf31b28800b24c29',
});

firebase.performance();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
serviceWorkerRegistration.register();
