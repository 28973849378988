import { startCase } from 'lodash';
import React from 'react';
import * as Yup from 'yup';

import Card from '../../../../../components/Cards/Card';
import { DishType } from '../../../../../models/enums/DishType';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import { ClassNameProps } from '../../../../../models/props/ClassNameProps';
import { filterEnums } from '../../../../../services/EnumService';
import {
  GetRecipeResponse,
  setActiveTime,
  setServings,
  setTime,
  setType,
} from '../../../../../services/RecipeService';
import InfoElement, { InfoElementProps } from './InfoElement';

type RecipeInfoCardProps = AuthenticatedUserProps & ClassNameProps & {
  id: string;
  recipe: GetRecipeResponse;
  edit?: boolean;
};

const RecipeInfoCard: React.FC<RecipeInfoCardProps> = (props) => {
  const infoElements: InfoElementProps[] = props.recipe
    ? [
        {
          label: 'Cost',
          value: `$5`,
        },
        {
          label: 'Time Required (minutes)',
          value: `${props.recipe.timeRequired}`,
          schema: Yup.number()
            .typeError('Must be an integer')
            .integer('Must be an integer')
            .min(1, 'Must be at least 1'),
          onUpdate: (value: number) => {
            setTime(props.id, value, props.userInfo.accessToken);
          },
        },
        {
          label: 'Active Time Required (minutes)',
          value: `${props.recipe.activeTimeRequired}`,
          schema: Yup.number()
            .typeError('Must be an integer')
            .integer('Must be an integer')
            .min(1, 'Must be at least 1'),
          onUpdate: (value: number) => {
            setActiveTime(props.id, value, props.userInfo.accessToken);
          },
        },
        {
          label: 'Type',
          value: startCase(props.recipe.type as string),
          dropdownKeys: filterEnums(Object.keys(DishType)),
          onUpdate: (value: DishType) => {
            setType(props.id, value, props.userInfo.accessToken);
          },
        },
        {
          label: 'Servings',
          value: `${props.recipe.servings}`,
          schema: Yup.number()
            .typeError('Must be an integer')
            .integer('Must be an integer')
            .min(1, 'Must be at least 1'),
          onUpdate: (value: number) => {
            setServings(props.id, value, props.userInfo.accessToken);
          },
        },
      ]
    : [];

  return (
    <Card className={props.className}>
      {infoElements.map((x, i) => (
        <InfoElement key={i} {...x} edit={props.edit} />
      ))}
    </Card>
  );
};

export default RecipeInfoCard;
