import { PaginatedListResponse } from '../models/PaginatedListResponse';
import { Roles } from '../models/enums/Roles';
import { get, HttpResponse, post, put } from './HttpService';

export interface ListUserResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailConfirmed: string;
  role: number;
  disabled: boolean;
}

export interface GetUserResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Roles | string;
  emailConfirmed: boolean;
  created: string;
  modified: string;
  lockedOut: boolean;
  disabled: boolean;
}

export function listUsers(
  page: number,
  token: string
): Promise<HttpResponse<PaginatedListResponse<ListUserResponse>>> {
  return get(`/api/auth/user`, { page }, token);
}

export function getUser(
  id: string,
  token: string
): Promise<HttpResponse<GetUserResponse>> {
  return get(`/api/auth/user/${id}`, {}, token);
}

export function setUserRole(id: string, role: Roles, token: string): Promise<HttpResponse> {
  return put(`/api/auth/user/${id}/role`, { role }, token);
}

export function setUserDisabled(id: string, disabled: boolean, token: string): Promise<HttpResponse> {
  return put(`/api/auth/user/${id}/disabled`, { disabled }, token);
}

export function unlockUser(id: string, token: string): Promise<HttpResponse> {
  return post(`/api/auth/user/${id}/unlock`, {}, token);
}
