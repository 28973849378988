import cx from 'classnames';
import React, { ChangeEventHandler, useState } from 'react';

import Caret from '../../assets/icons/caret.svg';

export type HoverEditDropdownProps = {
  classname?: string;
  value: string;
  dropdownKeys?: string[];
  onUpdate?: (value: any) => void;
};

const HoverEditDropdown: React.FC<HoverEditDropdownProps> = (props) => {
  const initialValue = props.dropdownKeys?.indexOf(props.value) ?? 0;
  const [value, setValue] = useState(initialValue < 0 ? 0 : initialValue);

  const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setValue(parseInt(e.target.value, 10));
    if (props.onUpdate) {
      props.onUpdate(e.target.value);
    }
  };

  return (
    <select
      className={cx(
        'w-full px-4 py-2 rounded transition-colors border border-transparent hover:border-gray-400 outline-none appearance-none bg-right bg-no-repeat bg-origin-content'
      )}
      onChange={onChange}
      value={value}
      style={{ backgroundImage: `url('${Caret}')` }}
    >
      {props.dropdownKeys?.map((x, i) => (
        <option key={i} value={i}>
          {x}
        </option>
      ))}
    </select>
  );
};

export default HoverEditDropdown;
