import cx from 'classnames';
import { Component } from 'react';
import Label from '../Typography/Label';

import TypeaheadField, { TypeaheadFieldProps } from './TypeaheadField';

type TypeaheadFieldWithLabelProps<T, V> = TypeaheadFieldProps<T, V> & {
  labelText: string;
};

export class TypeaheadFieldWithLabel<T, V> extends Component<
  TypeaheadFieldWithLabelProps<T, V>
> {
  render() {
    const fieldProps = {
      ...this.props,
      className: undefined,
    };
    
    return (
      <div className={cx(this.props.className, 'w-full')}>
        <Label className='mb-1'>{this.props.labelText}</Label>
        <TypeaheadField {...fieldProps}></TypeaheadField>
      </div>
    );
  }
}

export default TypeaheadFieldWithLabel;
