import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import PageWrapper from '../../../components/PageWrapper';
import { Page } from '../../../models/Page';
import AuthenticatedUserProps from '../../../models/props/AuthenticatedUserProps';
import RecipeCreateView from './recipes/create';
import RecipeListView from './recipes/RecipeListView';
import IngredientCreateView from './ingredients/IngredientCreateView';
import IngredientDetailView from './ingredients/IngredientDetailView';
import IngredientListView from './ingredients/IngredientListView';
import RecipeDetailView from './recipes/detail';

type RecipeListViewProps = AuthenticatedUserProps & RouteComponentProps;

class RecipeView extends Component<RecipeListViewProps> {
  private pages: Page[];

  constructor(props: RecipeListViewProps) {
    super(props);

    this.pages = [
      {
        name: 'Recipes',
        path: `${this.props.match.url}/recipes/`,
      },
      {
        name: 'Ingredients',
        path: `${this.props.match.url}/ingredients/`,
      },
    ];
  }

  render() {
    return (
      <PageWrapper name='Recipes' pages={this.pages}>
        <Switch>
          <Route path={`${this.props.match.url}/ingredients/create`}>
            <IngredientCreateView {...this.props} />
          </Route>
          <Route
            path={`${this.props.match.url}/ingredients/:id([A-Za-z0-9-]+)`}
          >
            <IngredientDetailView {...this.props} />
          </Route>
          <Route path={`${this.props.match.url}/ingredients/`}>
            <IngredientListView {...this.props} />
          </Route>
          <Route path={`${this.props.match.url}/recipes/create`}>
            <RecipeCreateView {...this.props} />
          </Route>
          <Route path={`${this.props.match.url}/recipes/:id([A-Za-z0-9-]+)/`}>
            <RecipeDetailView {...this.props} />
          </Route>
          <Route path={`${this.props.match.url}/recipes/`}>
            <RecipeListView {...this.props} />
          </Route>
        </Switch>
      </PageWrapper>
    );
  }
}

export default withRouter(RecipeView);
