import { BadRequestResponse } from '../models/responses/BadRequest';
import { errorToast } from './ToastService';

export interface HttpResponse<T = {}> {
  status: number;
  body?: T | BadRequestResponse;
  ok: boolean;
}

interface Query {
  [key: string]: any;
}

function http<T>(
  method: string,
  path: string,
  body?: any,
  token?: string
): Promise<HttpResponse<T>> {
  const bodyStr = body ? JSON.stringify(body) : undefined;
  const headers = new Headers();
  if (body) {
    headers.append('content-type', 'application/json');
  }

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  return fetch(`${process.env.REACT_APP_API_URL}${path}`, {
    method,
    body: bodyStr,
    headers,
  })
    .then(async (value) => {
      if (value.status === 401 && token) {
        window.location.reload();
        return { status: 401, ok: false };
      } else if (value.status >= 500) {
        errorToast(
          'There was a server error when processing your request. Please notify an administrator of this error and try again later.',
          'server-error'
        );
      }

      return {
        status: value.status,
        body: value.headers.has('content-type') ? await value.json() : {},
        ok: value.ok,
      };
    })
    .catch(() => {
      errorToast(
        'There was a network-related error when submitting your request. Please check your connection or try again later.',
        'network-error'
      );

      return {
        status: 0,
        ok: false,
      };
    });
}

function serialize(query: Query): string {
  var str = [];
  for (var p in query)
    if (query.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(query[p]));
    }
  return '?' + str.join('&');
}

export function get<T>(
  path: string,
  query?: Query,
  token?: string
): Promise<HttpResponse<T>> {
  if (query) {
    path += serialize(query);
  }

  return http('get', path, null, token);
}

export function post<T>(
  path: string,
  body?: any,
  token?: string
): Promise<HttpResponse<T>> {
  return http('post', path, body, token);
}

export function put<T>(
  path: string,
  body?: any,
  token?: string
): Promise<HttpResponse<T>> {
  return http('put', path, body, token);
}

export function del<T>(
  path: string,
  query?: Query,
  token?: string
): Promise<HttpResponse<T>> {
  if (query) {
    path += serialize(query);
  }

  return http('delete', path, null, token);
}
