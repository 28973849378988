import cx from 'classnames';
import { Field, FieldInputProps, FieldProps, FormikHelpers } from 'formik';
import { ChangeEvent, Component, FocusEvent, KeyboardEvent } from 'react';

import { PaginatedListResponse } from '../../models/PaginatedListResponse';
import { ClassNameProps } from '../../models/props/ClassNameProps';
import { FormikProps } from '../../models/props/FormikProps';

export type TypeaheadFieldProps<FormType, LookupType> = ClassNameProps &
  FormikProps<FormType> & {
    name: keyof FormType;
    placeholder?: string;

    onQuery: (query: string) => Promise<PaginatedListResponse<LookupType>>;
    text: (value: LookupType) => string;
    id: (value: LookupType) => string;
  };

type TypeaheadFieldState<LookupType> = {
  focused: boolean;
  hovering: boolean;
  results: LookupType[];
};

export class TypeaheadField<FormType, LookupType> extends Component<
  TypeaheadFieldProps<FormType, LookupType>,
  TypeaheadFieldState<LookupType>
> {
  name: string;
  searchName: string;

  constructor(props: TypeaheadFieldProps<FormType, LookupType>) {
    super(props);

    this.state = {
      hovering: false,
      focused: false,
      results: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setHover = this.setHover.bind(this);
    this.onKey = this.onKey.bind(this);

    this.name = this.props.name as string;
    this.searchName = `${this.props.name}-search`;
  }

  onChange(event: ChangeEvent<HTMLInputElement>, form: FormikHelpers<any>) {
    const value = event.target.value;
    this.search(value);
    form.setFieldValue(this.searchName, value);
  }

  onFocus(field: FieldInputProps<any>) {
    this.setState({ ...this.state, focused: true });
    this.search(field.value);
  }

  onBlur(e: FocusEvent, form: FormikHelpers<any>) {
    this.setState({ ...this.state, focused: false });
    form.setFieldTouched(this.props.name as string);
  }

  setHover(state: boolean) {
    this.setState({ ...this.state, hovering: state });
  }

  onKey(
    event: KeyboardEvent,
    field: FieldInputProps<any>,
    form: FormikHelpers<any>
  ) {
    if (
      event.key === 'Tab' &&
      this.state.results.length > 0 &&
      field.value !== this.props.text(this.state.results[0])
    ) {
      this.setValue(this.state.results[0], form);
      event.preventDefault();
    }
  }

  setValue(element: LookupType, form: FormikHelpers<any>) {
    this.setState({
      ...this.state,
      hovering: false,
      focused: false,
    });

    form.setFieldValue(this.name, this.props.id(element));
    form.setFieldValue(this.searchName, this.props.text(element));
  }

  async search(value: string) {
    if (!value) {
      return;
    }

    const results = await this.props.onQuery(value);
    this.setState({ ...this.state, results: results.items });
  }

  render() {
    const touched =
      this.props.touchedFn && this.props.touched
        ? this.props.touchedFn(this.props.touched)
        : this.props.touched[this.props.name] ?? false;
    const error =
      this.props.errorFn && this.props.errors
        ? this.props.errorFn(this.props.errors)
        : this.props.errors[this.props.name] ?? false;

    return (
      <div className={cx('relative', this.props.className)}>
        <Field name={this.name} type='text'>
          {({ field, form }: FieldProps) => (
            <>
              <input type='hidden' name={field.name} value={field.value} />
              <div
                className={cx(
                  'absolute top-12 py-2 w-full bg-white shadow rounded-lg',
                  {
                    hidden:
                      (!this.state.focused && !this.state.hovering) ||
                      this.state.results.length === 0,
                  }
                )}
                onMouseEnter={() => this.setHover(true)}
                onMouseLeave={() => this.setHover(false)}
              >
                {this.state.results.map((x, i) => (
                  <button
                    key={i}
                    type='button'
                    className='w-full p-2 text-left hover:bg-gray-200'
                    onClick={() => this.setValue(x, form)}
                  >
                    {this.props.text(x)}
                  </button>
                ))}
              </div>
            </>
          )}
        </Field>
        <Field name={this.searchName} type='text'>
          {({ field, form }: FieldProps) => (
            <>
              <input
                type='text'
                placeholder={this.props.placeholder}
                name={field.name}
                value={field.value || ''}
                onChange={(e) => this.onChange(e, form)}
                onFocus={() => this.onFocus(field)}
                onBlur={(e) => this.onBlur(e, form)}
                onKeyDown={(e) => this.onKey(e, field, form)}
                autoComplete='off'
                className={cx(
                  'w-full px-4 py-2 rounded transition-colors border border-gray-400 outline-none',
                  this.props.className,
                  {
                    'mb-6': !touched || !error,
                    'border-green-500': touched && !error,
                    'bg-red-100 border-red-500 mb-0': touched && error,
                  }
                )}
              />
              <div className='text-red-500 text-sm mt-1'>
                {touched ? error : ''}
              </div>
            </>
          )}
        </Field>
      </div>
    );
  }
}

export default TypeaheadField;
