import * as React from 'react';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import CenteredPageWrapper from '../../components/CenteredPageWrapper';
import LoginHandlerProps from '../../models/props/LoginHandlerProps';
import ConfirmEmail from './ConfirmEmail';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';

type PublicViewProps = LoginHandlerProps & {
  token?: string | null;
};

export default class PublicView extends React.Component<PublicViewProps> {
  render() {
    return this.props.token ? (
      <Redirect to='/' />
    ) : (
      <CenteredPageWrapper>
        <Switch>
          <Route path='/pub/login'>
            <Login onLogin={this.props.onLogin}></Login>
          </Route>
          <Route path='/pub/forgot-password'>
            <ForgotPassword></ForgotPassword>
          </Route>
          <Route path='/pub/reset-password'>
            <ResetPassword></ResetPassword>
          </Route>
          <Route path='/pub/register'>
            <Register></Register>
          </Route>
          <Route path='/pub/confirm-email'>
            <ConfirmEmail></ConfirmEmail>
          </Route>
        </Switch>
      </CenteredPageWrapper>
    );
  }
}
