import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Button from '../../../../../components/Button';
import TypeaheadFieldWithLabel from '../../../../../components/FormFields/TypeaheadFieldWithLabel';
import ValidatedEnumDropdownWithLabel from '../../../../../components/FormFields/ValidatedEnumDropdownWithLabel';
import ValidatedFieldWithLabel from '../../../../../components/FormFields/ValidatedFieldWithLabel';
import { PaginatedListResponse } from '../../../../../models/PaginatedListResponse';
import { Units } from '../../../../../models/enums/Units';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import {
  addIngredientToRecipe,
  ListIngredientResponse,
  listIngredients,
} from '../../../../../services/RecipeService';

type RecipeIngredientFormProps = AuthenticatedUserProps & {
  recipeId: string;
  onAdd: () => void;
};

interface IngredientFormValues {
  ingredientId: string;
  amount: number | string;
  unit: Units | string;
  instruction: string;
}

const RecipeIngredientForm: React.FC<RecipeIngredientFormProps> = (props) => {
  const searchIngredients = async (
    query: string
  ): Promise<PaginatedListResponse<ListIngredientResponse>> => {
    const response = await listIngredients(
      0,
      props.userInfo.accessToken,
      query
    );
    if (response.ok) {
      return response.body as PaginatedListResponse<ListIngredientResponse>;
    }

    return {
      items: [],
      page: 0,
      pageSize: 20,
      totalItems: 0,
      totalPages: 0,
      hasNextPage: false,
      hasPreviousPage: false,
    };
  };

  const addIngredient = async (
    value: IngredientFormValues,
    helpers: FormikHelpers<IngredientFormValues>
  ) => {
    const unit = parseInt(value.unit as string) as Units;
    const response = await addIngredientToRecipe(
      props.recipeId,
      value.ingredientId,
      unit,
      value.amount as number,
      value.instruction,
      props.userInfo.accessToken
    );

    if (response.ok) {
      helpers.resetForm();
      props.onAdd();
    }
  };

  const initialValues: IngredientFormValues = {
    ingredientId: '',
    amount: '',
    unit: Units.Unit,
    instruction: '',
  };

  const schema = Yup.object().shape({
    ingredientId: Yup.string().required('This field is required'),
    amount: Yup.number().required('This field is required').min(0),
    unit: Yup.number().required('This field is required'),
    instruction: Yup.string(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={addIngredient}
      validationSchema={schema}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className='grid grid-cols-3 lg:grid-cols-12 gap-4 items-center mt-8'>
          <TypeaheadFieldWithLabel
            className='col-span-3'
            labelText='Ingredient'
            touched={touched}
            errors={errors}
            placeholder='Ingredient'
            name='ingredientId'
            onQuery={searchIngredients}
            text={(value) => value.name}
            id={(value) => value.id}
          />

          <ValidatedFieldWithLabel
            className='col-span-3'
            type='text'
            labelText='Instruction'
            touched={touched}
            errors={errors}
            placeholder='Instruction'
            name='instruction'
          />

          <ValidatedFieldWithLabel
            className='col-span-3'
            type='number'
            labelText='Amount'
            touched={touched}
            errors={errors}
            placeholder='Amount'
            name='amount'
          />

          <ValidatedEnumDropdownWithLabel
            className='col-span-2'
            labelText='Units'
            touched={touched}
            errors={errors}
            placeholder='Units'
            name='unit'
            keys={Object.keys(Units)}
          />

          <Button type='submit' color='primary' disabled={isSubmitting}>
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RecipeIngredientForm;
