import React, { ChangeEventHandler, Component } from 'react';

import Label from '../Typography/Label';

import Caret from '../../assets/icons/caret.svg';

interface EnumDropdownFieldWithLabelProps {
  labelText: string;
  className?: string;
  value?: number;
  onChange: ChangeEventHandler<HTMLElement>;
  keys: string[];
}

export class EnumDropdownFieldWithLabel extends Component<EnumDropdownFieldWithLabelProps> {
  values: { key: string; value: number }[];

  constructor(props: EnumDropdownFieldWithLabelProps) {
    super(props);

    this.values = this.props.keys
      .filter((x) => isNaN(parseInt(x, 10)))
      .map((x, i) => ({ key: x, value: i }));
  }

  render() {
    return (
      <div className={this.props.className}>
        <Label className='mb-1'>{this.props.labelText}</Label>
        <select
          className='w-full px-4 py-2 rounded transition-colors border border-gray-400 outline-none appearance-none bg-right bg-no-repeat bg-origin-content'
          value={this.props.value}
          onChange={this.props.onChange}
          style={{ backgroundImage: `url('${Caret}')` }}
        >
          {this.values.map((x) => (
            <option key={x.value} value={x.value}>
              {x.key}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default EnumDropdownFieldWithLabel;
