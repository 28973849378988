import cx from 'classnames';
import React from 'react';

import { ClassNameProps } from '../models/props/ClassNameProps';

const VerticalButtonGroup: React.FC<ClassNameProps> = (props) => {
  return (
    <div className={cx('flex items-stretch gap-4 flex-col', props.className)}>
      {props.children}
    </div>
  );
};

export default VerticalButtonGroup;
