import cx from 'classnames';
import { ChangeEventHandler, Component } from 'react';

import Label from '../Typography/Label';

interface FieldWithLabelProps {
  type: string;
  placeholder?: string;
  labelText: string;
  className?: string;
  disabled?: boolean;
  value?: string;
  min?: number;
  max?: number;
  step?: number;
  onChange?: ChangeEventHandler<HTMLElement>;
}

export class FieldWithLabel extends Component<FieldWithLabelProps> {
  render() {
    return (
      <div className={this.props.className}>
        <Label className='mb-1'>{this.props.labelText}</Label>
        <input
          className={cx(
            'w-full px-4 py-2 rounded transition-colors border border-gray-400 outline-none',
            {
              'bg-white': !this.props.disabled,
              'bg-gray-300': this.props.disabled
            }
          )}
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value}
          readOnly={!!this.props.value && !this.props.onChange}
          onChange={this.props.onChange}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default FieldWithLabel;
