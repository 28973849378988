import { Form, Formik, FormikHelpers } from 'formik';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../components/Button';
import ValidatedFieldWithLabel from '../../components/FormFields/ValidatedFieldWithLabel';
import PageHeader from '../../components/Typography/PageHeader';
import VerticalButtonGroup from '../../components/VerticalButtonGroup';
import { forgotPassword } from '../../services/AuthService';
import { errorToast, successToast } from '../../services/ToastService';

type ForgotPasswordProps = RouteComponentProps<{}>;

interface ForgotPasswordFormValues {
  email: string;
}

class ForgotPassword extends Component<ForgotPasswordProps> {
  constructor(props: ForgotPasswordProps) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async handleFormSubmit(
    values: ForgotPasswordFormValues,
    helpers: FormikHelpers<ForgotPasswordFormValues>
  ) {
    const result = await forgotPassword(values.email);
    helpers.setSubmitting(false);

    if (result.ok) {
      this.props.history.push('/pub/login/');
      successToast('Check your email for a link to reset your password!');
    } else {
      errorToast(
        'Something went wrong when submitting the request. Try again later.',
        'forgot-password'
      );
    }
  }

  render() {
    const initialValues: ForgotPasswordFormValues = {
      email: '',
    };

    const schema = Yup.object().shape({
      email: Yup.string().email().required('This field is required'),
    });

    return (
      <>
        <PageHeader>Forgot Password</PageHeader>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleFormSubmit}
          initialValues={initialValues}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form>
                <ValidatedFieldWithLabel
                  labelText='Email address'
                  type='email'
                  placeholder='Email'
                  name='email'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <VerticalButtonGroup>
                  <Button type='submit' disabled={isSubmitting} color='primary'>
                    Submit
                  </Button>
                  <Button to='/pub/login/' color='secondary'>
                    Cancel
                  </Button>
                </VerticalButtonGroup>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withRouter(ForgotPassword);
