import React from 'react';
import { useHistory } from 'react-router';

import RecipeForm from '../../../../../components/RecipeForm';
import PageHeader from '../../../../../components/Typography/PageHeader';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import { Recipe } from '../../../../../models/recipes/Recipe';
import { createRecipe } from '../../../../../services/RecipeService';

type RecipeCreateViewProps = AuthenticatedUserProps;

const RecipeCreateView: React.FC<RecipeCreateViewProps> = (props) => {
  const history = useHistory();

  const onSubmit = async (recipe: Recipe, token: string) => {
    const result = await createRecipe(recipe, token);
    if (result.ok) {
      history.push(`../${result.body}/steps`);
    }
  }; 

  return (
    <>
      <PageHeader>Create Recipe</PageHeader>
      <RecipeForm onSubmit={onSubmit} {...props} />
    </>
  );
};

export default RecipeCreateView;
