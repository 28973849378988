import React from 'react';

import IngredientForm from '../../../../components/IngredientForm';
import PageHeader from '../../../../components/Typography/PageHeader';
import AuthenticatedUserProps from '../../../../models/props/AuthenticatedUserProps';
import { createIngredient } from '../../../../services/RecipeService';

type IngredientCreateViewProps = AuthenticatedUserProps;

const IngredientCreateView: React.FC<IngredientCreateViewProps> = (props) => {
  return (
    <>
      <PageHeader>Create Ingredient</PageHeader>
      <IngredientForm onSubmit={createIngredient} {...props} />
    </>
  );
};

export default IngredientCreateView;
