import cx from 'classnames';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react';
import { AnySchema, ValidationError } from 'yup';

export type HoverEditFieldProps = {
  classname?: string;
  value: string;
  schema?: AnySchema;
  styleAsClass?: string;
  textarea?: boolean;
  rows?: number;
  onUpdate?: (value: any) => void;
};

const HoverEditField: React.FC<HoverEditFieldProps> = (props) => {
  const [value, setValue] = useState(props.value);
  const [existingValue, setExistingValue] = useState(props.value);
  const [errorText, setErrorText] = useState('');

  const validate = async (value: any) => {
    let result = value;
    if (props.schema) {
      try {
        result = await props.schema.validate(value);
      } catch (e: any) {
        const error = e as ValidationError;
        setErrorText(error.message);
        return null;
      }
    }

    setErrorText('');
    return result;
  };

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e
  ) => {
    setValue(e.target.value);
    validate(e.target.value);
  };

  const onBlur = async () => {
    const result = await validate(value);
    if (!result) {
      return;
    }

    if (props.onUpdate && value !== existingValue) {
      setExistingValue(value);
      props.onUpdate(result);
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const className = cx(
    'w-full px-4 py-2 rounded transition-colors border border-transparent hover:border-gray-400 focus:border-gray-400 outline-none',
    props.styleAsClass,
    {
      'bg-red-100 border-red-500': errorText.length > 0,
    }
  );

  return (
    <div className={props.classname}>
      {props.textarea && props.rows ? (
        <textarea
          className={className}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          rows={props.rows}
        />
      ) : (
        <input
          className={className}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      )}
      <div className='text-red-500 text-sm mt-1'>{errorText}</div>
    </div>
  );
};

export default HoverEditField;
