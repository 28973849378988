import cx from 'classnames';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Card from '../../../../components/Cards/Card';
import PwaTips from '../../../../components/Cards/PwaTips';
import ReloadGameTips from '../../../../components/Cards/ReloadGameTips';
import Spinner from '../../../../components/Spinner';
import database from '../../../../models/Database';
import { DominoGame } from '../../../../models/games/DominoGame';
import { GameWrapper } from '../../../../models/games/GameWrapper';
import DominoScoreEntry from './DominoScoreEntry';

import { ReactComponent as DominoSvg } from '../../../../assets/images/domino.svg';

type DominoGameComponentProps = RouteComponentProps<{ id: string }>;

type DominoGameComponentState = {
  game?: GameWrapper<DominoGame>;
  entryIndex?: number;
};

export class DominoGameComponent extends Component<
  DominoGameComponentProps,
  DominoGameComponentState
> {
  constructor(props: DominoGameComponentProps) {
    super(props);

    this.state = {};

    this.openScoreEntry = this.openScoreEntry.bind(this);
    this.setScores = this.setScores.bind(this);
    this.closeScoreEntry = this.closeScoreEntry.bind(this);
  }

  async componentDidMount() {
    const id = parseInt(this.props.match.params.id);
    const game = await database.dominoGames.get(id);
    if (!game) {
      this.props.history.push('/site/tools/domino/');
    }

    this.setState({ game });
  }

  async setScores(scores: number[]) {
    const index = this.state.entryIndex!;
    const game: GameWrapper<DominoGame> = { ...this.state.game! };
    game.game!.scores[index] = scores;

    this.setState({ game, entryIndex: undefined });
    await database.dominoGames.put(game);
  }

  openScoreEntry(index: number) {
    this.setState({ entryIndex: index });
  }

  closeScoreEntry() {
    this.setState({ entryIndex: undefined });
  }

  render() {
    const game = this.state.game;

    let totals = game?.game.scores.reduce((a, b) =>
      a.map((x, i) => x + (b[i] ?? 0))
    );

    if (totals?.length === 0) {
      totals = game?.players.map(() => 0);
    }

    const cellClass = 'py-1 px-2';

    return (
      <div className='grid lg:grid-cols-3 xl:grid-cols-4 gap-8'>
        <div className='col-span-1 lg:col-span-2 xl:col-span-3'>
          {game ? (
            this.state.entryIndex !== undefined ? (
              <DominoScoreEntry
                players={game.players}
                existingScores={game.game.scores[this.state.entryIndex]}
                domino={game.game.dominoSize - this.state.entryIndex}
                setScores={this.setScores}
                cancelEntry={this.closeScoreEntry}
              />
            ) : (
              <>
                <table className='w-full text-left'>
                  <thead>
                    <tr className='border-b-2 border-black'>
                      <th>
                        <DominoSvg className='transform origin-center rotate-90 w-6 translate-x-1/2' />
                      </th>
                      {game.players.map((x, i) => (
                        <th key={i} className={cellClass}>
                          {x}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {game.game.scores.map((scores, i, arr) => (
                      <tr
                        key={i}
                        onClick={() => this.openScoreEntry(i)}
                        className={cx('hover:bg-gray-300 cursor-pointer', {
                          'bg-gray-100': i % 2 === 1,
                          'border-black border-b-2': i === arr.length - 1,
                        })}
                      >
                        <td
                          className={cx(
                            cellClass,
                            'w-24 border-r-2 border-black'
                          )}
                        >
                          {game.game.dominoSize - i}
                        </td>
                        {game.players.map((_, j) => (
                          <td key={j} className={cellClass}>
                            {scores[j]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th>
                      {totals?.map((x, i) => (
                        <td key={i} className={cellClass}>
                          {x}
                        </td>
                      ))}
                    </tr>
                  </tfoot>
                </table>
              </>
            )
          ) : (
            <>
              <h1>Loading your game...</h1>
              <Spinner />
            </>
          )}
        </div>
        <div>
          <Card title='How to use the domino tracker' className='mb-3'>
            <p>
              First, you'll have to play a round of dominos. Once you've done
              that, click the row corresponding to the domino in the center of
              the table.
            </p>
            <p>
              From there, all you have to do its enter the scores for all of the
              players at the table and click save!
            </p>
          </Card>
          <ReloadGameTips name='domino score tracker' className='mb-3' />
          <PwaTips className='mb-3' />
        </div>
      </div>
    );
  }
}

export default withRouter(DominoGameComponent);
