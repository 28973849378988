import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';

import { Page } from '../models/Page';
import Areas from '../models/enums/Areas';
import { get, HttpResponse, post } from './HttpService';

interface PasswordRequirements {
  digit: boolean;
  uppercase: boolean;
  lowercase: boolean;
  symbol: boolean;
  length: number;
}

interface ApiLoginResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  refreshToken: string;
  accessToken: string;
  areaAccess: string[];
}

export interface LoginResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  refreshToken: string;
  accessToken: string;
  areaAccess: Page[];
}

export function login(
  email: string,
  password: string
): Promise<HttpResponse<LoginResponse>> {
  return post<ApiLoginResponse>('/api/auth/login', {
    email,
    password,
  }).then((response) => convertLoginResponse(response));
}

export function loginToken(
  refreshToken: string
): Promise<HttpResponse<LoginResponse>> {
  return post<ApiLoginResponse>('/api/auth/token', {
    refreshToken,
  }).then((response) => convertLoginResponse(response));
}

export function forgotPassword(email: string): Promise<HttpResponse<{}>> {
  return post('/api/auth/forgot-password', { email });
}

export function resetPassword(
  email: string,
  token: string,
  password: string
): Promise<HttpResponse<{}>> {
  return post('/api/auth/reset-password', { email, token, password });
}

export function confirmEmail(
  email: string,
  token: string
): Promise<HttpResponse<{}>> {
  return post('/api/auth/confirm-email', { email, token });
}

export function register(
  email: string,
  password: string,
  firstName: string,
  lastName: string
): Promise<HttpResponse<{}>> {
  return post('/api/auth/register', { email, password, firstName, lastName });
}

export async function getPasswordRequirements(): Promise<
  RequiredStringSchema<any>
> {
  const response = await get<PasswordRequirements>(
    '/api/auth/password-requirements'
  );

  let schema = Yup.string();
  if (!response.ok || !response.body) {
    return schema;
  }

  const requirements = response.body as PasswordRequirements;
  if (requirements.digit) {
    schema = schema.matches(/.*[0-9].*/, 'Password must contain a digit.');
  }

  if (requirements.lowercase) {
    schema = schema.matches(
      /.*[a-z].*/,
      'Password must contain a lowercase letter.'
    );
  }

  if (requirements.uppercase) {
    schema = schema.matches(
      /.*[A-Z].*/,
      'Password must contain an uppercase letter.'
    );
  }

  if (requirements.symbol) {
    schema = schema.matches(
      /.*[`~!@#$%^&*()\-_=+[\]{}|\\;:'",<.>/?].*/,
      'Password must contain a symbol.'
    );
  }

  return schema.min(
    requirements.length,
    `Password must be at least ${requirements.length} characters long.`
  );
}

function convertLoginResponse(
  response: HttpResponse<ApiLoginResponse>
): HttpResponse<LoginResponse> {
  const body = response.body as ApiLoginResponse;

  return {
    status: response.status,
    ok: response.ok,
    body:
      response.body && response.ok
        ? {
            id: body.id,
            firstName: body.firstName,
            lastName: body.lastName,
            email: body.email,
            accessToken: body.accessToken,
            refreshToken: body.refreshToken,
            areaAccess: body.areaAccess
              .filter((x) => Areas[x])
              .map((x) => Areas[x]),
          }
        : undefined,
  };
}
