import { Form, Formik, FormikHelpers } from 'formik';
import qs from 'qs';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';

import Button from '../../components/Button';
import ValidatedFieldWithLabel from '../../components/FormFields/ValidatedFieldWithLabel';
import PageHeader from '../../components/Typography/PageHeader';
import VerticalButtonGroup from '../../components/VerticalButtonGroup';
import {
  getPasswordRequirements,
  resetPassword,
} from '../../services/AuthService';
import { errorToast, successToast } from '../../services/ToastService';

type ResetPasswordProps = RouteComponentProps<{}>;

interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

interface ResetPasswordState {
  requirements: RequiredStringSchema<any>;
  token: string;
  email: string;
}

class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);

    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      requirements: Yup.string(),
      token: query.token as string,
      email: query.email as string,
    };

    if (!this.state.token || !this.state.email) {
      this.props.history.push('/pub/login/');
    }

    getPasswordRequirements().then((x) => this.setState({ requirements: x }));
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async handleFormSubmit(
    values: ResetPasswordFormValues,
    helpers: FormikHelpers<ResetPasswordFormValues>
  ) {
    const result = await resetPassword(
      this.state.email,
      this.state.token,
      values.password
    );
    helpers.setSubmitting(false);

    if (result.ok) {
      this.props.history.push('/pub/login/');
      successToast('Your password has been successfully reset!');
    } else {
      errorToast(
        'Something went wrong when resetting your password. You may need to submit the forgot password form again.',
        'reset-password'
      );
    }
  }

  render() {
    const initialValues: ResetPasswordFormValues = {
      password: '',
      confirmPassword: '',
    };

    const schema = Yup.object().shape({
      password: this.state.requirements.required('This field is required.'),
      confirmPassword: Yup.string()
        .required('This field is required.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    return (
      <>
        <PageHeader>Reset Password</PageHeader>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={this.handleFormSubmit}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form>
                <ValidatedFieldWithLabel
                  labelText='Password'
                  type='password'
                  placeholder='Password'
                  name='password'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <ValidatedFieldWithLabel
                  labelText='Confirm password'
                  type='password'
                  placeholder='Confirm password'
                  name='confirmPassword'
                  touched={touched}
                  errors={errors}
                ></ValidatedFieldWithLabel>

                <VerticalButtonGroup>
                  <Button color='primary' type='submit' disabled={isSubmitting}>
                    Submit
                  </Button>
                  <Button to='/pub/login/' color='secondary'>
                    Cancel
                  </Button>
                </VerticalButtonGroup>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withRouter(ResetPassword);
