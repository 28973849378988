import { ChangeEvent, Component } from 'react';

import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel';
import { GameSettingsComponentProps } from '../../../../components/GameSettingsComponent';
import { DominoGame } from '../../../../models/games/DominoGame';

type DominoSettingsComponentState = {
  size: number;
};

export class DominoSettingsComponent extends Component<
  GameSettingsComponentProps<DominoGame>,
  DominoSettingsComponentState
> {
  constructor(props: GameSettingsComponentProps<DominoGame>) {
    super(props);

    this.state = { size: 12 };
    this.update(12);

    this.setSize = this.setSize.bind(this);
  }

  setSize(event: ChangeEvent<HTMLInputElement>) {
    const size = event.target.valueAsNumber;

    this.setState({ size });
    if (size > 15) {
      this.update(15);
    } else if (size < 6) {
      this.update(6);
    } else {
      this.update(size);
    }
  }

  update(size: number) {
    if (!this.props.setCustomSettings) {
      return;
    }

    const scores: number[][] = [];
    for (let i = 0; i <= size; i++) {
      scores.push([]);
    }

    this.props.setCustomSettings({ dominoSize: size, scores: scores });
  }

  render() {
    return (
      <FieldWithLabel
        type='number'
        labelText='Domino size'
        value={`${this.state.size}`}
        onChange={this.setSize}
        max={15}
        min={6}
        step={1}
      />
    );
  }
}

export default DominoSettingsComponent;
