import { startCase } from 'lodash';

export function filterEnums(arr: any[]): string[] {
  const output: string[] = [];
  for (const element of arr) {
    if (typeof element === 'string' && isNaN(parseInt(element, 10))) {
      output.push(startCase(element));
    }
  }

  return output;
}
