import { Component } from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';
import Card from './Card';

export class PwaTips extends Component<ClassNameProps> {
  render() {
    return (
      <Card
        title='Not going to have internet soon?'
        className={this.props.className}
      >
        This website can be installed as a Progressive Web App. That basically
        means that it can be installed on your phone and look and feel like a
        mobile app. On most browsers, you'll get a prompt asking you to install
        this site when you load it up. Just click that button and it'll be
        installed. On iOS Safari, go to the settings page for this site and
        click "Add to home page".
      </Card>
    );
  }
}

export default PwaTips;
