import cx from 'classnames';
import React from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';
import SectionSubSubHeader from '../Typography/SectionSubSubHeader';

type CardProps = ClassNameProps & {
  title?: string;
};

const Card: React.FC<CardProps> = (props) => {
  return (
    <div className={cx(props.className, 'border rounded-xl p-4')}>
      {props.title && <SectionSubSubHeader>{props.title}</SectionSubSubHeader>}
      <div className='text-sm flex flex-col gap-2'>{props.children}</div>
    </div>
  );
};

export default Card;
