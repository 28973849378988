import cx from 'classnames';
import React from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';

const SectionSubHeader: React.FC<ClassNameProps> = (props) => {
  return (
    <h3 className={cx(props.className, 'text-lg font-semibold mb-3')}>
      {props.children}
    </h3>
  );
};

export default SectionSubHeader;
