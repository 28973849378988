import cx from 'classnames';
import { Field } from 'formik';
import { Component } from 'react';
import { FormikProps } from '../../models/props/FormikProps';

export type ValidatedFieldProps<T> = FormikProps<T> & {
  type: string;
  placeholder?: string;
  name: keyof T;
  className?: string;
  autocomplete?: boolean;
  disabled?: boolean;
  as?: string;
  rows?: number;
  min?: number;
  max?: number;
  step?: number;
}

export default class ValidatedField<T> extends Component<
  ValidatedFieldProps<T>
> {
  render() {
    const touched =
      this.props.touchedFn && this.props.touched
        ? this.props.touchedFn(this.props.touched)
        : this.props.touched[this.props.name] ?? false;
    const error =
      this.props.errorFn && this.props.errors
        ? this.props.errorFn(this.props.errors)
        : this.props.errors[this.props.name] ?? false;

    return (
      <>
        <Field
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          rows={this.props.rows}
          as={this.props.as}
          type={this.props.type}
          placeholder={this.props.placeholder}
          name={this.props.name}
          autoComplete={this.props.autocomplete ?? true ? 'on' : 'off'}
          className={cx(
            'w-full px-4 py-2 rounded transition-colors border border-gray-400 outline-none',
            this.props.className,
            {
              'mb-6': !touched || !error,
              'border-green-500': touched && !error,
              'bg-red-100 border-red-500 mb-0': touched && error,
            }
          )}
        />
        <div className='text-red-500 text-sm mt-1'>{touched ? error : ''}</div>
      </>
    );
  }
}
