import cx from 'classnames';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

type ButtonProps = (
  | ButtonHTMLAttributes<HTMLButtonElement>
  | AnchorHTMLAttributes<HTMLAnchorElement>
) & {
  to?: string;
  sm?: boolean;
  disabled?: boolean;
  color: 'primary' | 'secondary' | 'danger' | 'success';
};

const Button: React.FC<ButtonProps> = (props) => {
  let className = cx(
    props.className,
    'rounded-lg shadow font-semibold text-center text-base inline-block',
    {
      'px-2 py-0.5': props.sm,
      'px-4 py-2': !props.sm,
      'cursor-not-allowed': props.disabled,
    }
  );

  if (props.color === 'primary') {
    className = cx(
      className,
      'text-white hover:bg-blue-400 active:bg-blue-700',
      {
        'bg-blue-400': props.disabled,
        'bg-blue-500': !props.disabled,
      }
    );
  } else if (props.color === 'secondary') {
    className = cx(
      className,
      'text-white hover:bg-gray-400 active:bg-gray-700',
      {
        'bg-gray-400': props.disabled,
        'bg-gray-500': !props.disabled,
      }
    );
  } else if (props.color === 'danger') {
    className = cx(className, 'text-white hover:bg-red-400 active:bg-red-700', {
      'bg-red-400': props.disabled,
      'bg-red-500': !props.disabled,
    });
  } else if (props.color === 'success') {
    className = cx(
      className,
      'text-white hover:bg-green-400 active:bg-green-700',
      {
        'bg-green-400': props.disabled,
        'bg-green-500': !props.disabled,
      }
    );
  }

  if (props.to) {
    const anchorProps = props as AnchorHTMLAttributes<HTMLAnchorElement>;

    return (
      <Link
        {...anchorProps}
        onClick={anchorProps.onClick}
        to={props.to}
        className={className}
      >
        {props.children}
      </Link>
    );
  }

  const buttonProps = props as ButtonHTMLAttributes<HTMLButtonElement>;
  return (
    <button
      onClick={buttonProps.onClick}
      className={className}
      disabled={props.disabled}
      type={buttonProps.type}
    >
      {props.children}
    </button>
  );
};

export default Button;
