import React from 'react';

import Button from '../Button';
import HorizontalButtonGroup from '../HorizontalButtonGroup';
import SectionHeader from '../Typography/SectionHeader';
import Modal from './Modal';

type ConfirmModalProps = {
  text: string;
  title: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  return (
    <Modal onClose={props.onCancel}>
      <SectionHeader>{props.title}</SectionHeader>
      <p>{props.text}</p>
      <HorizontalButtonGroup className='mt-4'>
        <Button color='danger' onClick={props.onConfirm}>
          {props.confirmText ?? 'Yes'}
        </Button>
        <Button color='secondary' onClick={props.onCancel}>
          {props.cancelText ?? 'Cancel'}
        </Button>
      </HorizontalButtonGroup>
    </Modal>
  );
};

export default ConfirmModal;
