import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';

import Table from '../../../../components/Table';
import PageHeader from '../../../../components/Typography/PageHeader';
import AuthenticatedUserProps from '../../../../models/props/AuthenticatedUserProps';
import {
  ListIngredientResponse,
  listIngredients,
} from '../../../../services/RecipeService';

type IngredientListViewProps = AuthenticatedUserProps;

const IngredientListView: React.FC<IngredientListViewProps> = (props) => {
  const history = useHistory();

  const tableProps = {
    accessToken: props.userInfo.accessToken,
    columns: [
      {
        name: 'Name',
        get: (elem: ListIngredientResponse) => elem.name,
      },
    ],
    load: (page: number, token: string) => listIngredients(page, token),
    rowClick: (row: ListIngredientResponse) => history.push(`./${row.id}/`),
    disabled: (row: ListIngredientResponse) => row.disabled,
  };

  return (
    <div>
      <div className='flex justify-between items-start mb-2'>
        <PageHeader>Ingredient List</PageHeader>
        <Button color='primary' to={`./create/`}>
          Create
        </Button>
      </div>
      <Table {...tableProps} />
    </div>
  );
};

export default IngredientListView;
