import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';

import Button from '../../../../../components/Button';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import Spinner from '../../../../../components/Spinner';
import SectionHeader from '../../../../../components/Typography/SectionHeader';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import { ClassNameProps } from '../../../../../models/props/ClassNameProps';
import {
  GetRecipeIngredientResponse,
  getRecipeIngredients,
  removeIngredientFromRecipe,
} from '../../../../../services/RecipeService';
import RecipeIngredientForm from './RecipeIngredientForm';

type RecipeIngredientCardProps = ClassNameProps &
  AuthenticatedUserProps & {
    edit: boolean;
    recipeId: string;
  };

const RecipeIngredients: React.FC<RecipeIngredientCardProps> = (props) => {
  const [ingredients, setIngredients] = useState<
    GetRecipeIngredientResponse[] | null
  >(null);

  const [toRemove, setToRemove] = useState<GetRecipeIngredientResponse | null>(
    null
  );

  const loadIngredients = useCallback(async () => {
    const response = await getRecipeIngredients(
      props.recipeId,
      props.userInfo.accessToken
    );
    if (!response.ok) {
      return;
    }

    setIngredients(response.body as GetRecipeIngredientResponse[]);
  }, [props.recipeId, props.userInfo.accessToken]);

  const removeIngredient = async (id: string) => {
    await removeIngredientFromRecipe(
      props.recipeId,
      id,
      props.userInfo.accessToken
    );

    await loadIngredients();
    hideRemoveModal();
  };

  const showRemoveModal = (ingredient: GetRecipeIngredientResponse) => {
    setToRemove(ingredient);
  };

  const hideRemoveModal = () => {
    setToRemove(null);
  };

  useEffect(() => {
    loadIngredients();
  }, [loadIngredients, props.recipeId, props.userInfo.accessToken]);

  return (
    <div>
      <SectionHeader>Ingredients</SectionHeader>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
        {ingredients ? (
          ingredients.map((x, i) => (
            <div key={i}>
              <div>
                <span>
                  {x.amount} {x.unit} {x.name}
                </span>
                {props.edit && (
                  <Button
                    sm
                    color='danger'
                    className='ml-4'
                    onClick={() => showRemoveModal(x)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </div>
              <div className='text-sm text-gray-500'>{x.instructions}</div>
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </div>
      {props.edit && (
        <RecipeIngredientForm onAdd={loadIngredients} {...props} />
      )}
      {toRemove && (
        <ConfirmModal
          text={`Are you sure you want to remove ${toRemove.name} from this recipe?`}
          title={'Remove Ingredient'}
          confirmText='Delete'
          cancelText='Cancel'
          onConfirm={() => removeIngredient(toRemove.ingredientId)}
          onCancel={hideRemoveModal}
        />
      )}
    </div>
  );
};

export default RecipeIngredients;
