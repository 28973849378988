import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../../components/Button';
import Table from '../../../../components/Table';
import PageHeader from '../../../../components/Typography/PageHeader';
import AuthenticatedUserProps from '../../../../models/props/AuthenticatedUserProps';
import { Recipe } from '../../../../models/recipes/Recipe';
import { listRecipes } from '../../../../services/RecipeService';

type RecipeListViewProps = AuthenticatedUserProps;

const RecipeListView: React.FC<RecipeListViewProps> = (props) => {
  const history = useHistory();

  const tableProps = {
    accessToken: props.userInfo.accessToken,
    columns: [
      {
        name: 'Name',
        get: (elem: Recipe) => elem.name,
      },
    ],
    load: (page: number, token: string) => listRecipes(token),
    rowClick: (row: Recipe) => history.push(`./${row.id}/`),
    disabled: (row: Recipe) => false,
  };

  return (
    <>
      <div className='flex justify-between items-start mb-2'>
        <PageHeader>Recipe List</PageHeader>
        <Button color='primary' to={`./create/`}>
          Create
        </Button>
      </div>
      <Table {...tableProps} />
    </>
  );
};

export default RecipeListView;
