import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Spinner from '../../../../../components/Spinner';
import AuthenticatedUserProps from '../../../../../models/props/AuthenticatedUserProps';
import {
  getRecipe,
  GetRecipeResponse,
} from '../../../../../services/RecipeService';
import RecipeHeaderCard from './RecipeHeaderCard';
import RecipeInfoCard from './RecipeInfoCard';
import RecipeIngredients from './RecipeIngredients';
import RecipeSteps from './RecipeSteps';

type RecipeDetailViewProps = AuthenticatedUserProps;

type RecipeDetailViewRouteParams = {
  id: string;
};

const RecipeDetailView: React.FC<RecipeDetailViewProps> = (props) => {
  const params = useParams<RecipeDetailViewRouteParams>();
  const id = params.id;
  const [recipe, setRecipe] = useState<GetRecipeResponse>();
  const [edit, setEdit] = useState(false);

  const fetchRecipe = useMemo(
    () => async () => {
      const response = await getRecipe(id, props.userInfo.accessToken);

      if (response.ok) {
        setRecipe(response.body as GetRecipeResponse);
      }
    },
    [id, props.userInfo.accessToken]
  );

  useEffect(() => {
    fetchRecipe();
  }, [fetchRecipe]);

  const toggleEdit = () => {
    fetchRecipe();
    setEdit(!edit);
  };

  return recipe ? (
    <div>
      <div className='grid gap-y-4'>
        <RecipeHeaderCard
          {...props}
          recipe={recipe}
          id={id}
          edit={edit}
          toggleEdit={toggleEdit}
        />
        <RecipeInfoCard {...props} recipe={recipe} id={id} edit={edit} />
        <RecipeIngredients {...props} recipeId={id} edit={edit} />
        <RecipeSteps {...props} recipeId={id} edit={edit} />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default RecipeDetailView;
