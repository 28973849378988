import { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SiteWrapper from '../../components/SiteWrapper';
import { LoginResponse } from '../../services/AuthService';
import AdministrationView from './administration/AdministrationView';
import RecipeView from './recipes';
import ToolsView from './tools/ToolsView';

type SiteViewProps = {
  onLogout: () => void;
  userInfo?: LoginResponse;
};

export default class SiteView extends Component<SiteViewProps> {
  route(name: string) {
    if (name === 'Administration') {
      return (
        <AdministrationView
          onLogout={this.props.onLogout}
          userInfo={this.props.userInfo!}
        />
      );
    }

    if (name === 'Tools') {
      return (
        <ToolsView
          onLogout={this.props.onLogout}
          userInfo={this.props.userInfo!}
        />
      );
    }

    if (name === 'Recipes') {
      return (
        <RecipeView
          onLogout={this.props.onLogout}
          userInfo={this.props.userInfo!}
        />
      );
    }

    return <></>;
  }

  render() {
    return this.props.userInfo?.accessToken ? (
      <SiteWrapper
        userInfo={this.props.userInfo}
        onLogout={this.props.onLogout}
      >
        <Switch>
          {this.props.userInfo.areaAccess.map((x) => (
            <Route key={x.name} path={`${x.path}/`}>
              {this.route(x.name)}
            </Route>
          ))}
        </Switch>
      </SiteWrapper>
    ) : (
      <Redirect to='/pub/login/' />
    );
  }
}
