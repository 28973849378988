import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { confirmEmail } from '../../services/AuthService';
import { errorToast, successToast } from '../../services/ToastService';

type ConfirmEmailProps = RouteComponentProps<{}>;

interface ConfirmEmailState {
  token: string;
  email: string;
}

export class ConfirmEmail extends Component<
  ConfirmEmailProps,
  ConfirmEmailState
> {
  constructor(props: ConfirmEmailProps) {
    super(props);

    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      token: query.token as string,
      email: query.email as string,
    };

    if (!this.state.token || !this.state.email) {
      this.props.history.push('/pub/login/');
    }

    confirmEmail(this.state.email, this.state.token).then((result) => {
      this.props.history.push('/pub/login/');
      if (result.ok) {
        successToast('Your email was successfully confirmed!');
      } else if (result.status !== 0) {
        errorToast(
          "Hmmm... something went wrong when confirming your email. Try the link in your email again. If that doesn't work, try the forgot password option below.",
          'confirm-email'
        );
      }
    });
  }

  render() {
    return <div>Email confirmation page</div>;
  }
}

export default withRouter(ConfirmEmail);
