import React from 'react';

import HoverEditDropdown, {
  HoverEditDropdownProps,
} from '../../../../../components/HoverEditField/HoverEditDropdown';
import HoverEditField, {
  HoverEditFieldProps,
} from '../../../../../components/HoverEditField/HoverEditField';

export type InfoElementProps = (
  | HoverEditDropdownProps
  | HoverEditFieldProps
) & {
  className?: string;
  label: string;
  edit?: boolean;
};

const InfoElement: React.FC<InfoElementProps> = (props) => {
  let editField: JSX.Element;
  if ((props as HoverEditDropdownProps).dropdownKeys) {
    editField = <HoverEditDropdown className='' {...props} />;
  } else {
    editField = <HoverEditField className='' {...props} />;
  }

  return (
    <div className={props.className}>
      <div className='font-semibold'>{props.label}</div>
      {props.onUpdate && props.edit ? editField : <div>{props.value}</div>}
    </div>
  );
};

export default InfoElement;
