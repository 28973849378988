import { PaginatedListResponse } from '../models/PaginatedListResponse';
import { DishType } from '../models/enums/DishType';
import { IngredientTypes } from '../models/enums/IngredientTypes';
import { Units } from '../models/enums/Units';
import { Ingredient } from '../models/recipes/Ingredient';
import { Recipe } from '../models/recipes/Recipe';
import { del, get, HttpResponse, post, put } from './HttpService';
import { successToast } from './ToastService';

export interface ListIngredientResponse {
  id: string;
  name: string;
  type: IngredientTypes;
  disabled: boolean;
}

export type GetRecipeResponse = Recipe & {};

export type GetRecipeIngredientResponse = {
  ingredientId: string;
  name: string;
  amount: number;
  unit: Units;
  instructions: string;
  cost: number;
};

export type GetRecipeStepResponse = {
  order: number;
  instruction: string;
};

export type GetIngredientResponse = Ingredient & {
  canDisable: boolean;
};

export function createIngredient(ingredient: Ingredient, token: string) {
  return post<{}>('/api/recipe/ingredients', ingredient, token);
}

export function listIngredients(
  page: number,
  token: string,
  query?: string
): Promise<HttpResponse<PaginatedListResponse<ListIngredientResponse>>> {
  return get<PaginatedListResponse<ListIngredientResponse>>(
    '/api/recipe/ingredients',
    { page: `${page}`, query: query ?? '' },
    token
  );
}

export function getIngredient(
  id: string,
  token: string
): Promise<HttpResponse<GetIngredientResponse>> {
  return get<GetIngredientResponse>(
    `/api/recipe/ingredients/${id}`,
    {},
    token
  ).then((x) => {
    if (x.ok) {
      const body = x.body as GetIngredientResponse;
      body.type = IngredientTypes[body.type] as any;
    }

    return x;
  });
}

export function editIngredient(
  ingredient: Ingredient,
  token: string
): Promise<HttpResponse> {
  return put<{}>(`/api/recipe/ingredients/${ingredient.id}`, ingredient, token);
}

export function disableIngredient(
  id: string,
  token: string
): Promise<HttpResponse> {
  return post<{}>(`/api/recipe/ingredients/${id}/disable`, {}, token);
}

export function enableIngredient(
  id: string,
  token: string
): Promise<HttpResponse> {
  return post<{}>(`/api/recipe/ingredients/${id}/enable`, {}, token);
}

export function createRecipe(
  recipe: Recipe,
  token: string
): Promise<HttpResponse<string>> {
  return post<string>(`/api/recipe`, recipe, token);
}

export function listRecipes(
  token: string
): Promise<HttpResponse<PaginatedListResponse<Recipe>>> {
  return get(`/api/recipe`, {}, token);
}

export function getRecipe(
  id: string,
  token: string
): Promise<HttpResponse<GetRecipeResponse>> {
  return get(`/api/recipe/${id}`, {}, token);
}

export async function setServings(
  id: string,
  servings: number,
  token: string
): Promise<HttpResponse> {
  const response = await put<{}>(
    `/api/recipe/${id}/servings`,
    { servings },
    token
  );
  if (response.ok) {
    successToast(`Successfully updated servings!`);
  }

  return response;
}

export async function setTime(
  id: string,
  time: number,
  token: string
): Promise<HttpResponse> {
  const response = await put<{}>(`/api/recipe/${id}/time`, { time }, token);
  if (response.ok) {
    successToast(`Successfully updated servings!`);
  }

  return response;
}

export async function setActiveTime(
  id: string,
  time: number,
  token: string
): Promise<HttpResponse> {
  const response = await put<{}>(
    `/api/recipe/${id}/active-time`,
    { time },
    token
  );
  if (response.ok) {
    successToast(`Successfully updated servings!`);
  }

  return response;
}

export async function setType(
  id: string,
  type: DishType,
  token: string
): Promise<HttpResponse> {
  const response = await put<{}>(`/api/recipe/${id}/type`, { type }, token);
  if (response.ok) {
    successToast(`Successfully updated type!`);
  }

  return response;
}

export async function setName(
  id: string,
  name: string,
  token: string
): Promise<HttpResponse> {
  const response = await put<{}>(`/api/recipe/${id}/name`, { name }, token);
  if (response.ok) {
    successToast(`Successfully updated the title!`);
  }

  return response;
}

export async function setDescription(
  id: string,
  description: string,
  token: string
): Promise<HttpResponse> {
  const response = await put<{}>(
    `/api/recipe/${id}/description`,
    { description },
    token
  );
  if (response.ok) {
    successToast(`Successfully updated the description!`);
  }

  return response;
}

export async function addIngredientToRecipe(
  recipeId: string,
  ingredientId: string,
  unit: Units,
  amount: number,
  instruction: string,
  token: string
): Promise<HttpResponse> {
  const response = await post<{}>(
    `/api/recipe/${recipeId}/ingredient`,
    { ingredientId, unit, amount, instruction },
    token
  );
  if (response.ok) {
    successToast(`Successfully added the ingredient!`);
  }

  return response;
}

export async function getRecipeIngredients(
  recipeId: string,
  token: string
): Promise<HttpResponse<GetRecipeIngredientResponse[]>> {
  return await get<GetRecipeIngredientResponse[]>(
    `/api/recipe/${recipeId}/ingredient`,
    {},
    token
  );
}

export async function addStepToRecipe(
  recipeId: string,
  instruction: string,
  token: string
): Promise<HttpResponse> {
  const response = await post<{}>(
    `/api/recipe/${recipeId}/step`,
    { instruction },
    token
  );
  if (response.ok) {
    successToast(`Successfully added the step!`);
  }

  return response;
}

export async function getRecipeSteps(
  recipeId: string,
  token: string
): Promise<HttpResponse<GetRecipeStepResponse[]>> {
  return await get<GetRecipeStepResponse[]>(
    `/api/recipe/${recipeId}/step`,
    {},
    token
  );
}

export async function removeIngredientFromRecipe(
  recipeId: string,
  ingredientId: string,
  token: string
): Promise<HttpResponse> {
  const response = await del<{}>(
    `/api/recipe/${recipeId}/ingredient/${ingredientId}`,
    {},
    token
  );
  if (response.ok) {
    successToast(`Successfully removed the ingredient!`);
  }

  return response;
}

export async function removeStepFromRecipe(
  recipeId: string,
  order: number,
  token: string
): Promise<HttpResponse> {
  const response = await del<{}>(
    `/api/recipe/${recipeId}/step/${order}`,
    {},
    token
  );
  if (response.ok) {
    successToast(`Successfully removed the step!`);
  }

  return response;
}
