import cx from 'classnames';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type NavbarLinkProps = NavLinkProps & {
  color: string;
  activeColor: string;
};

const NavbarLink: React.FC<NavbarLinkProps> = (props) => {
  return (
    <NavLink
      onClick={props.onClick}
      className={cx(
        `text-${props.color} py-2 px-4 rounded-md transition-colors`,
        props.className
      )}
      activeClassName={`text-gray-900 bg-${props.activeColor}`}
      to={props.to}
    >
      {props.children}
    </NavLink>
  );
};

export default NavbarLink;
