export enum Units {
  Pound,
  Gram,
  Ounce,
  FluidOunce,
  Kilogram,
  Cup,
  Gallon,
  Quart,
  Pint,
  Unit,
  Tablespoon,
  Teaspoon
}
