import cx from 'classnames';
import React from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';

const SectionSubSubHeader: React.FC<ClassNameProps> = (props) => {
  return (
    <h4 className={cx(props.className, 'text-base font-semibold mb-2')}>
      {props.children}
    </h4>
  );
};

export default SectionSubSubHeader;
