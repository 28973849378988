import cx from 'classnames';
import React from 'react';

import { ClassNameProps } from '../../models/props/ClassNameProps';

const Label: React.FC<ClassNameProps> = (props) => {
  return (
    <label className={cx('text-sm block', props.className)}>
      {props.children}
    </label>
  );
};

export default Label;
