import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Table, { TableProps } from '../../../components/Table';
import PageHeader from '../../../components/Typography/PageHeader';
import AuthenticatedUserProps from '../../../models/props/AuthenticatedUserProps';
import { listUsers, ListUserResponse } from '../../../services/UserService';

type UserListProps = AuthenticatedUserProps & RouteComponentProps;

export class UserList extends Component<UserListProps> {
  tableProps: TableProps<ListUserResponse>;

  constructor(props: UserListProps) {
    super(props);

    this.state = {};

    this.tableProps = {
      accessToken: this.props.userInfo.accessToken,
      columns: [
        {
          name: 'Email',
          get: (elem: ListUserResponse) => elem.email,
        },
        {
          name: 'First name',
          get: (elem: ListUserResponse) => elem.firstName,
        },
        {
          name: 'Last name',
          get: (elem: ListUserResponse) => elem.lastName,
        },
      ],
      load: (page: number, token: string) => listUsers(page, token),
      rowClick: (row: ListUserResponse) =>
        this.props.history.push(`/site/admin/${row.id}/`),
      disabled: (row: ListUserResponse) => row.disabled,
    };
  }

  render() {
    return (
      <>
        <PageHeader>User List</PageHeader>
        <Table {...this.tableProps} />
      </>
    );
  }
}

export default withRouter(UserList);
